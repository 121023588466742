
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    :is(.section__rail) {
  .blockquote {
    margin: 0 auto;
    width: 100%;
    &__inner {
      padding: 1rem;
      transform: none;
      gap: 1rem;
    }
    &__quote {
      font-size: 1.75rem;
      &:before,
      &:after {
        font-size: 2.5rem;
        position: relative;
        top: .5rem;
        left: -.5rem;
      }
      // &:after {
      //   content: '”';
      // }
    }
  }
}
.blockquote {
  $offset: 5.125rem;
  display: flex;
  margin: auto;
  width: 90%;
  max-width: $maxWidth;
  border-radius: 0.625rem;
  justify-content: center;
  margin: 0 auto calc($offset);
  font-size: 1rem;
  @include mqdn(lg) {
    $minFont: 14;
    $maxFont: 16;
    $minVp: 768;
    $maxVp: 992;
    font-size: calc(#{$minFont}px + ( #{$maxFont} - #{$minFont} ) * ( (100vw - #{$minVp}px) / (#{$maxVp} - #{$minVp}) ) );
  }
  &__inner {
    
    background-color: var(--fill-white);
    color: var(--fill-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3.1875em;
    max-width: 70.875rem;
    width: 90%;
    border-radius: 0.5rem;
    transform: translateY($offset);
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,0.20);
    padding: 10.25em 4.875em 3.5625em;
    font-family: $fontFamilyHeadings;
    @include mqdn(lg) {
      padding: 4.5rem 2.5rem;
    }
  }
  &__quote {
    font-style: italic;
    font-size: 2em;
    text-align: center;
    margin: 0;
    &:before {
      
      @include mqdn(lg) {
        $minFont: 222;
        $maxFont: 252;
        $minVp: 768;
        $maxVp: 992;
        font-size: calc(#{$minFont}px + ( #{$maxFont} - #{$minFont} ) * ( (100vw - #{$minVp}px) / (#{$maxVp} - #{$minVp}) ) );
      }
      $fontSize: 15.75rem;
      font-family: $fontFamilyHeadings;
      content: '“';
      font-size: $fontSize;
      line-height: 1;
      position: absolute;
      left: 0;
      right: 0;
      top: calc(($fontSize * .1) * -1);
      margin: auto;
    }
  }
  &__cite {
    font-size: 1.25em;
    text-align: center;
  }
}
  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    /**
 * 1. Make the dialog container, and its child overlay spread across
 *    the entire window.
 */
.dialog {

  &__container,
  &__overlay {
    position: fixed;
    /* 1 */
    top: 0;
    /* 1 */
    right: 0;
    /* 1 */
    bottom: 0;
    /* 1 */
    left: 0;
    /* 1 */
  }

  /**
   * 1. Make sure the dialog container and all its descendants sits on
   *    top of the rest of the page.
   * 2. Make the dialog container a flex container to easily center the
   *    dialog.
   */
  &__container {
    z-index: 2;
    /* 1 */
    display: flex;

    /* 2 */
    /**
   * 1. Make sure the dialog container and all its descendants are not
   *    visible and not focusable when it is hidden.
   */
    &[aria-hidden='true'] {
      display: none;
      /* 1 */
    }
  }

  /**
   * 1. Make the overlay look like an overlay.
   */
  &__overlay {
    background-color: rgba(43, 46, 56, 0.9);
    /* 1 */
  }

  /**
   * 1. Vertically and horizontally center the dialog in the page.
   * 2. Make sure the dialog sits on top of the overlay.
   * 3. Make sure the dialog has an opaque background.
   */
  &__content {
    background: $white;
    border: solid 1px $gray;
    box-shadow: 0 0 0.5rem rgba($black, 0.25), 0 0 1.125rem rgba($black, 0.1);
    min-width: 20rem;
    max-width: 69.875rem;
    max-height: 90%;
    overflow: scroll;
    padding: 1rem 1.25rem;
    margin: auto;
    /* 1 */
    z-index: 2;
    /* 2 */
    position: relative;
    @media screen and (max-width: 25rem) {
      padding-top: 3.25rem;
    }
    &--overflow-hidden {
      overflow: hidden;
    }

    &--overflow-y {
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__close {
    @include clean();
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.25rem;
  }

  &__media {
    --width: 100%;
    --height: 100%;
    // max-width: 1280px;
    max-width: 100%;
    width: 80vw;

    @include mq(md) {
      width: 125vh;
    }

    overflow: hidden;

    video,
    iframe {
      object-fit: contain;
      aspect-ratio: var(--width) / var(--height);
      height: 100%;
      width: 100%;
    }
  }
}



/**
 * 1. Add an animation delay equal to the overlay animation duration to
 *    wait for the overlay to appear before animation in the dialog.
 */
 .dialog__overlay {
  animation: fade-in 200ms both;
}
.dialog__content {
  animation: fade-in 400ms 200ms both, slide-up 400ms 200ms both;
  /* 1 */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}
  
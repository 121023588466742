
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .hero-banner {
  background-image: linear-gradient(180deg, #003366 0%, #004C97 53%);
  min-height: 280px;
  color: var(--fill-white);
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  max-width: none;
  width: 100vw;
  display: flex;
  gap: 1.25rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  z-index: 1;
  // uncomment if breadcrumbs come back
  // flex-direction: column;
  @each $name, $color in $fills {
    &.u-background--fill-#{'' + $name} {
      color: choose-contrast-color($color);
    }
  }
  &.u-background--fill-photo {
    background-color:#000;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;

    .hero-banner__title,
    .hero-banner__copy {
      text-shadow: 0 1px 3px rgba(0,0,0,.25);
    }
    
    .hero-banner__media {
      @include mqdn(lg) {
        display: none;
      }
    }
  }
  &.u-background--fill-ruby {
    background-image: linear-gradient(180deg, #6B1328 0%, #9B2743 52%);
  }
  &.u-background--fill-cool-gray,
  &.u-background--fill-tan {
    .hero-banner__title {
      color: var(--fill-blue);
    }
    .hero-banner__cta {
      .btn {
        background-color: var(--fill-ruby);
        &:hover {
          background-color: #8b233c;
        }
      }
    }
  }
  &:not(.hero-banner--home):not(.hero-banner--event) {
    .hero-banner{
      &__background{
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        overflow: hidden;
        filter: grayscale(1);
        opacity: 15%;
      }
      &__content,
      &__inner {
        max-width: $maxWidth;
        width: 100vw;
      }
    }
  }
  &--large,
  &--image{
    min-height: 360px;
  }
  &--small {
    min-height: 212px;
  }
  &--image {
    position: relative;
  }
  &--flipped {
    .hero-banner__inner {
      flex-direction: row-reverse;
    }
  }
  &--home {
    $bannerMediaOffset: 3rem;
    padding: 2rem;
    margin-bottom: 2rem;
    @include mqdn(lg) {
      background-image:linear-gradient(180deg, #036 0%, #004C97 53%,  #004C97 90%, #0000 90%, #0000 100%)
    }
    @include mq(lg) {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 641px;
      margin-bottom: $bannerMediaOffset + 1rem;
    }
    .hero-banner__inner {
      align-items: center;
      max-width: 93.75rem;

      @include mqdn(lg) {
        flex-direction: column;
      }
    }
    .hero-banner__media,
    .hero-banner__background {
      
      @include mq(lg) {
        position: relative;
        top: $bannerMediaOffset;
        flex: 0 0 auto;
      }
      
    }
    .hero-banner__image {
      max-width: 42.25rem;
    }
    .hero-banner__content {
      @include mqdn(lg) {
        text-align: center;
      }
    }
    .hero-banner__title {
      font-size: clamp(2.5rem, 2.5vw, 3.875rem);
      line-height: 1.16;
    }
    .hero-banner__copy {
      font-size: clamp(1.125rem, 2.5vw, 1.875rem);
      margin-bottom: 1.5rem;
    }
    .hero-banner__cta{
      
      @include mqdn(lg) {
        flex-direction: column;
        width: 100%;
      }
    }

    &.u-background--fill-ruby {
      @include mqdn(lg) {
        background-image:linear-gradient(180deg, #6B1328 0%, #9B2743  53%,  #9B2743  90%, #0000 90%, #0000 100%)
      }
    }

    &.hero-banner--small {
      $bannerMediaOffset: 2rem;
      @include mq(lg) {
        min-height: unset;
      }

      .hero-banner__inner {
        gap:2rem;
      }

      .hero-banner__media,
      .hero-banner__background {
        @include mq(md) {
          position: relative;
          top: calc($bannerMediaOffset / 2);
          flex: 0 0 40%;
        }
      }

      .hero-banner__image {
        display:block;
        margin:0 auto;
        max-width:27.5rem;
      }

      .hero-banner__title {
        font-size: clamp(2rem, 2.5vw, 3.875rem);
      }
      .hero-banner__copy {
        font-size: clamp(1rem, 2vw, 1.75rem);
      }
    }
    &.hero-banner--small-padding{
      @include mq(lg) {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }
    }
  }

  &--event {
    padding: 2rem;    
    @include mq(lg) {
      // padding-bottom: 0;
      min-height: 405px;
    }
    .hero-banner__inner {
      justify-content: space-between;
      
      @include mqdn(lg) {
        align-items: start;
        flex-direction: column-reverse;
      }
    }
    .hero-banner__content {
      @include mq(lg) {
        width: 45%;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
    .hero-banner__media{
      @include mq(lg) {
        width: 100%;
        height: 100%;
        max-width: 720px;
        margin: auto;
      }
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
      max-width: none;
      width: 100vw;
      aspect-ratio: 16/9;
      
    }
    .hero-banner__image {
      
    }
    .hero-banner__video {
      // border-radius: 0.625rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      pointer-events: auto;
      aspect-ratio: 16/9;
    }
    .hero-banner__title {
      @include mq(lg) {
        font-size: 2.25rem;
        line-height: 1.16;
      }
      margin-bottom: 1.5rem;
    }
    .hero-banner__list {
      @include mq(lg) {
        font-size: 1.25rem;
      }
      
      margin-bottom: 1.5rem;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 0.5rem;
      }
      i {
        margin-right: 0.5rem;
        width: 1.5rem;
        display: inline-flex;
      }
      a {
        color: currentColor;
      }
    }
    .hero-banner__multi-day {
      display:inline-block;
      margin-left:1rem;
      font-size: 13px;
      text-transform:lowercase;
      background: $warmGray;
      color: $brandBlue;
      padding:.3725em .675em .4em;
      position: relative;
      top:-2px;
      line-height:1;
      border-radius: .5em;
      font-weight:bold;
      box-shadow:0 1px 1px rgba(0,0,0,.15), inset 0 1px 1px #fff;
    }
    .hero-banner__cta{
      
      @include mqdn(lg) {
        flex-direction: column;
        width: 100%;
      }
    }
    &.u-background--fill-ruby {
      @include mqdn(lg) {
        background-image:linear-gradient(180deg, #6B1328 0%, #9B2743  53%,  #9B2743  90%, #0000 90%, #0000 100%)
      }
    }
  }
  &__media {

  }
  &__image,
  &__video,
  &__background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // block covers picture element padding issues
    display: block;
    margin: auto;
    pointer-events: none;
  }
  &__content{
    max-width: 49.1875rem;
  }
  &__inner {
    max-width: $maxWidth;
    width: 100vw;
    align-items: center;
    display: flex;
    gap: 1rem;
  }
  &__title {
    // font-family: $fontFamilyBase;
    margin-bottom: 0;
  }
  &__copy { 
    margin: 1.5rem auto 0;
  }
  &__cta {
    font-size: 1rem;
    display: flex;
    margin: 0.5rem 0 0;
    gap: 1rem;
    width: 95%;
    a:not(.btn) {
      color: currentColor;
    }
    @include mqdn(md) {
      flex-wrap: wrap;
      .btn {
        flex: 1 1 100%;
      }
    }
  }

}
  
@keyframes glyph-loading {
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideInFromBelow {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideInFromAbove {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
//for the <main> element (in between header and footer) on the site
table:not(:where(.grant-list *)) {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    caption {
        font-size: $h3Size;
        text-align: left;
    }

    thead {
        background-color: var(--fill-blue);
        color: #fff;
        text-align: left;

        tr>* {
            border-right: 1px solid #fff;

            &:last-of-type {
                border-right: none;
            }
        }

        th {
            &:first-of-type {
                border-radius: $cornerRound 0 0 0;
            }

            &:last-of-type {
                border-radius: 0 $cornerRound 0 0;
            }
        }
    }

    tbody {
        color: #000;

        tr {
            background-color: var(--fill-cool-gray);
            border-bottom: 1px solid #fff;

            >* {
                border-right: 1px solid #fff;

                &:last-of-type {
                    border-right: none;
                }
            }

            &:nth-of-type(even) {
                background-color: var(--fill-light-blue);
            }

            &:last-of-type {
                td {
                    &:first-of-type {
                        border-radius: 0 0 0 $cornerRound;
                    }

                    &:last-of-type {
                        border-radius: 0 0 $cornerRound 0;
                    }
                }
            }
        }
    }

    tfoot {
        tr {
            background-color: var(--fill-warm-gray);
        }
    }

    td,
    th {
        padding: $paddingMd;
    }
}
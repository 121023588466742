
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .tabs {
    // @include mqdn(tabMobile) {
    //     display: flex;
    //     gap: 1rem;
    //     overflow: auto;
    // }
    &--county {
        // slight hack for the padding on the page
        :where(.composition--padded) > &:first-of-type {
            @include mq(tabMobile) {
                display: block;
                margin-top: -3rem;
            }
        }
    }
    &--inner {
        .tabs__nav {
            width: auto;
            margin: auto;
            background-color: unset;
            border-bottom: 5px solid var(--fill-cool-gray);
        }
        .tabs__nav-inner {
            justify-content: start;
            gap: .7rem;
        }
        .tabs__tab {
            background-color: var(--fill-cool-gray);
            &[aria-selected='true'],
            &:hover {
                background-color: var(--fill-ruby);
                color: var(--fill-white);
            }
            &:after {
                display: none;
            }
            &--end {
                all: unset;
                align-self: center;
                margin-left: auto;
                appearance: none;
                padding: .5rem 1rem;
                width: auto;
                cursor: pointer;
                text-align: center;
                color: var(--fill-blue);
                border: 1px solid var(--fill-blue);
                border-radius: 0.3125rem;
                font-weight: 400;
            }
        }
        .tabs__focus {
            small {
                display: block;
            }
        }
    }
    &__nav {   
        background-color: var(--fill-cool-gray);
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--fill-warm-gray);
        height: max-content;
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);
        max-width: none;
        width: 100vw;

        .tabs--agenda & {
            @include mqdn(tabMobile) {
                background-color: #fff;
            }
        }
    }
    &__nav-inner {
        // border-bottom: 1px solid var(--fill-cool-gray);
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: center;
        max-width: $maxWidth;
        @include mqdn(tabMobile) {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            justify-content: start;
            max-width: none;
            // box-shadow: inset -5px 0px 10px 0px rgba(0,0,0,0.5);
        }

        .tabs--agenda & {
            @include mqdn(tabMobile) {
                padding-top:2rem;
                flex-direction:column;
                gap:.75rem;
                max-width: calc(100% - 1.5rem);
                margin:0 auto 2rem;
            }
        }
    }
    &__tab {
        @include clean;
        color: var(--fill-black);
        padding: 1.5rem clamp(2rem, 2vw, 4rem);
        position: relative;
        border-right: 1px solid var(--fill-warm-gray);
        text-align: center;
        &:nth-of-type(1) {
            border-left: 1px solid var(--fill-warm-gray);
        }
        &:hover,
        &:focus,
        &[aria-selected='true']{
            border-right: 1px solid var(--fill-warm-gray);
            font-weight: 600;
            text-decoration: none;
            outline: none;
            &:after{
                width: 100%;
                left: 0;
                right: 0;
            }
        }
        &:after{ 
            content: '';
            position: absolute;
            width: 0;
            height: 4px;
            bottom: 0;
            left: 50%;
            right: 50%;
            background: var(--fill-ruby);
            -webkit-transition: .2s ease;
            transition: .2s ease;
        }
        @include mqdn(tabMobile) {
            min-width: 13.75rem;
        }

        .tabs--agenda & {
            @include mqdn(tabMobile) {
                border:0;
                padding: 1.25rem clamp(2rem, 2vw, 4rem);
                border-radius:.5rem;
                background-color: var(--fill-cool-gray);

                &::after {display:none;}
                &[aria-selected='true'] {background-color: var(--fill-ruby); color: var(--fill-white);}
            }
        }

        .tabs--agenda &--end {
            @include mqdn(tabMobile) {
                background:none;
                font-size:90%;
                padding:.5rem .25rem;
                font-weight:bold;
            }
        }
    }
    &__focus {
        letter-spacing: .05em;
        @include mqdn(tabMobile) {
            display: flex;
            flex-direction: column;
        }
    }
    &__tabpanel {
        margin-top: 3rem;
        &.is-hidden {
            display: none;
        }
    }
    
}
  
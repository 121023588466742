
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    :is(.section__rail) {
  .link-list {
    width: auto;
    margin: 0 auto;
    &.link-list--alt.link-list--3-col{
      .link-list__item {
        flex: 1 1 auto;
      }
    }
    + * {
      margin-top: 0;
    }
    &__inner{
    display: flex;
    flex-direction: column;
    transform: none;
    gap: .5rem;
    }
    &__column {
      flex: 1 1 auto;
      &:first-of-type{
        flex: 1 1 auto;
      }
    }
    &__item {
      flex: 1 1 auto;
      display: flex;
      
    }
    &__content {
      padding: 2rem;
    }
    &__media {
      transform: none;
    }
  }
}
.link-list {
  $colGap: clamp(4rem, 50vw, 10rem);
  // background-color: var(--fill-cool-gray);
  margin: 0 calc(-50vw + 50%);
  max-width: none;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  @each $name, $color in $fills {
    :where(.composition--fill-#{'' + $name}) & {
      color: choose-contrast-color($color);
      .link-list__title {
        color: choose-contrast-color($color);
      }
      .link-list__link {
        color: choose-contrast-color($color);
      }
      .link-list__icon {
        color: choose-contrast-color($color);
        &:hover {
          color: choose-contrast-color($color);
        }
      }
    }
  }
  :where(.composition--fill-tan) &{
    .link-list__link {
      color: choose-contrast-color($warmGray);
    }
  }
  @include mqdn(md) {
    margin-top: 1rem;
  }
  &--flipped {
    &:before {
      right: -10vw;
      left: auto;
    }
    .link-list__inner {
      @include mq(lg) {
        flex-direction: row-reverse;
      }
    }
  }
  &--alt {
    background-color: transparent;
    padding: 0 1rem;
    margin: 0 auto;
    width: auto;
    &:before {
      display: none;
    }
    .link-list__column {
      flex-basis: auto;
      &:first-of-type {
        flex-basis: auto;
      }
    }
    .link-list__inner {
      flex-direction: column;
      gap: 0;
    }
    .link-list__icon {
      font-size: 1.5em;
    }
    &.link-list--3-col {
      .link-list__list {
        gap: 5%;
      }
      .link-list__item {
        flex-basis: 30%;
      }
    }
    &.link-list--4-col {
      .link-list__list {
        gap: 5%;
      }
      .link-list__item {
        flex-basis: 20%;
      }
    }
  }
  &__inner {
    @include container($margin: 1.5rem);
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
      gap: 1rem;
    @include mq(lg) {
      gap: $colGap;
      align-items: center;
      flex-direction: row;
    }
  } 
  &__column {
    text-align: left;
    display: flex;
    flex-direction: column;
    @include mq(md) {
      
      justify-content: center;
      flex-basis: 67%;
      &:first-of-type {
        flex-basis: 33%;
        width: 100%;
      }
    }
  }
  &__title {
    color: var(--fill-ruby);
    margin-bottom: 1rem;
  }
  &__copy {
    
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    
    // column-count: 2;
    // column-fill: balance;
    // column-gap: $colGap;
    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      gap: 0 10%;
    }
  }
  &__item {
    padding: 1.25rem 0;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem 0;
    @include mq(md) {
      display: inline-flex;
      flex-basis: 40%;
    }
    &:hover {
      .link-list__icon{
        color: $linkColorHover;
      }
    }
  }
  &__icon {
    margin-right: 1rem;
    color: $linkColor;
    line-height: 0;
  }
  &__link {
    display: inline-flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 600;
    &:hover{
      text-decoration: none;
    }
  }
  &__item-copy {
    flex-basis: 100%;
    margin-bottom: 0;
    font-size: 1rem;
  }
}
  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .video {
  $videoCorner: 0.625rem;
  $contentPadLeft: 9.0625rem;
  $contentPadTopSm: 4.6875rem;
  $videoPadTop: 1rem;
  $videoMax: 61.25rem;
  display: flex;
  flex-direction: column;
  padding-top: $videoPadTop;
  max-width: $videoMax;
  margin: 0 auto $videoPadTop;
  &__media{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    margin-bottom: 0;
    aspect-ratio: 16/9;
    justify-self: center;
  }
  &__play {
    $size: 3rem;
    @include clean();
    height: $size;
    width: $size;
    position: absolute;
      // needs an offset
      top: calc($size * -1);
      right: 0;
      bottom: 0;
      left: 0;
    margin: auto;
    font-size: $size;
    color: var(--fill-white);
    &:hover,
    &:focus {
      color: var(--fill-white);
      i {
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  &__image,
  &__video {
    // position: absolute;
    // inset: 0;
    // object-fit: cover;
    // object-position: 50% 50%;
    // height: 100%;
    // width: 100%;
    // border: none;
    // transition: 0.2s ease;
    // max-width: 100%;

    * {
      max-width: 100%;
      // border-radius: $videoCorner;
    }
  }

  &__image {
    * {
      // object-fit: cover;
      // object-position: 50%;
      // max-width: 100%;
      // height: 100%;
      // width: 100%;
    }
  }
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  &__caption {
    font-size: 0.875rem;
  }
  &__content {
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
    @each $name, $color in $fills {
      &.u-background--fill-#{'' + $name} {
        background-color: $color;
        color: choose-contrast-color($color);
        .video__cta a:not(.btn) {
          color: choose-contrast-color($color);
          &:after {
            color: choose-contrast-color($color);
          }
        }
      }
    }
  }

  &__title {
    // font-size: $h3Size;
    // font-family: $fontFamilyHeadings;
    // line-height: $h3SizeLineHeight;
    margin: 0 0 0.75rem;
  }

  &__copy {
    @include wysiwyg;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0;
    line-height: 1.5;

    & > * {
      margin: 0;
    }
  }
}

  
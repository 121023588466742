.listing {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__items {
    margin-bottom: 2rem;
    &:is([data-view="list"] *) {
      display: flex;
      flex-direction: column;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid var(--fill-cool-gray);
      border-top:1px solid var(--fill-cool-gray);
      gap: 2rem;
    }

    &:is([data-view="grid"] *) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(Min(330px, 100%), 1fr));
      gap: 2rem;
    }
  }
}
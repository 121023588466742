// --------------------------------------------
// General typography styles
// --------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
hr,
ul,
ol,
dl,
blockquote,
p,
address,
figure,
pre {
  margin: 0 0 $marginBase;
}

ol li ol,
ul li ul {
  margin-bottom:0;
}

li {
  font-size: $liSize;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4,
.display {
  font-family: $fontFamilyHeadings;
  font-weight: $fontWeightHeadings;
}
h5,
h6,
.h5,
.h6 {
  font-family: $fontFamilyBase;
  font-weight: 600;
}
.display {
  font-size: $heroSize;
  line-height: $heroSizeLineHeight;
}
h1,
.h1 {
  font-size: $h1Size;
  line-height: $h1SizeLineHeight;
  @include mqdn(md) {
    font-size: $h1SizeMobile;
  }
}

h2,
.h2 {
  font-size: $h2Size;
  line-height: $h2SizeLineHeight;
  @include mqdn(md) {
    font-size: $h2SizeMobile;
  }
}

h3,
.h3 {
  font-size: $h3Size;
  line-height: $h3SizeLineHeight;
  @include mqdn(md) {
    font-size: $h3SizeMobile;
  }
}

h4,
.h4 {
  font-size: $h4Size;
  line-height: $h4SizeLineHeight;
  @include mqdn(md) {
    font-size: $h4SizeMobile;
  }
}

h5,
.h5 {
  font-size: $h5Size;
  line-height: $h5SizeLineHeight;
  @include mqdn(md) {
    font-size: $h5SizeMobile;
  }
}

h6,
.h6 {
  font-size: $h6Size;
  line-height: $h6SizeLineHeight;
  @include mqdn(md) {
    font-size: $h6SizeMobile;
  }
}
p {
  font-size: calc($fontSizeBase * 1.125);
  small {
    font-size: calc($fontSizeBase * 0.875);
  }
}
hr {
  margin-top: $marginBase;
  border: none;
  border-top: 1px solid;
}

a {
  transition: all 0.25s ease-in-out;
  color: $linkColor;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  &:hover {
    color: $linkColorHover;
    text-decoration: underline;
  }
  &[href*="mailto"] {
    @include mqdn(sm) {
      word-break: break-word;
    }
  }
}

[class^='glyph-'],
[class*=' glyph-'] {
  &::before {
    @include icon;
  }
}

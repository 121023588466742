
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .rail-item {
  &__inner {
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    background-color: var(--fill-cool-gray);
    border-radius: 0.625rem;
    padding: 2rem 1.5rem;
  }
  &__title {
    font-size: $h3Size;
    font-family: $fontFamilyHeadings;
    font-weight: bold;
  }
  &__copy {

  }
  
  &__cta {
    .btn {
      min-width: auto;
    }
  }
}
.rail-item-contact{
  display: flex;
  flex-direction: column;
  gap: .75rem;
  &__item {
    display: flex;
    
    gap: clamp(.5rem, 10vw, 1.5rem);
  }
}
  
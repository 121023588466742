
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .list-toggle {
  &__inner {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: end;
  }
  &__item {
    @include clean();
    border-bottom: 2px solid var(--fill-cool-gray);
    display: flex;
    align-items: center;
    gap: .25rem;
    &.is-active,
    &:hover,
    &:focus {
      border-bottom:2px solid var(--fill-ruby);
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }
}
  
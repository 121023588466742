
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .event-list-item {
  // used for calculating the transform
  --mediaSize: 4.625rem;
  $innerPad: 1.5rem;
  color: var(--fill-black);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  @include mqdn(lg) {
    --mediaSize: 5rem;
  }
  :where(.grid--match-height) &{
    display: flex;
    .event-list-item__inner {
      height: auto;
      align-self: stretch;
      flex: 1 1 100%;
      grid-template-rows: .1fr .25fr auto 1fr;
    }
  }
  :where([data-view="list"]) & {
    @include mq(md) {
      .event-list-item__inner {
        display: grid;
        grid-template-columns: minmax(7.8125rem, .35fr) 1.75fr 1fr;
        grid-template-rows: .15fr .25fr 1fr;
        gap:.25rem .5rem;
        grid-template-areas:
          "media meta cta"
          "media title cta"
          "media copy cta" ;
        padding: 1rem 1.5rem .5rem 1.5rem;
        align-items: center;
      }
      .event-list-item__media {
        max-width: 7.8125rem;
        max-height: 7.8125rem;

      }
      .event-list-item__copy {
        margin-bottom: 0;

        &:empty {
          display:none;
        }
      }
      .event-list-item__cta {
        border-top-width: 0;
        padding-top: 0;
        border-left: 1px solid var(--fill-cool-gray);
        padding-left: 1.3125rem;
        justify-content: center;
        align-self: stretch;
      }
    }

  }
  :where([data-view="grid"]) & {
    .event-list-item__inner {
      height:100%;
    }
    .event-list-item__media {
      justify-self:flex-end;
    }
    .event-list-item__title {
      min-width:180px;
    }
  }
  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
    "meta"
    "title"
    "copy "
    "cta";
    gap: .15rem;
    border-radius: $cornerRound;
    padding: 1rem;
    background: var(--fill-white);

    @include mq(sm) {
      grid-template-columns: 1fr;
      grid-template-rows: .1fr .25fr 1fr auto;
      grid-template-areas:
      "meta media"
      "title media"
      "copy copy"
      "cta cta";
      gap: 0 .5rem;
    }

    @include mq(md) {
      gap: 0 1.5rem;
      padding:1.5rem;
    }
  }
  &__media {
    display:none;
    border-radius: 8px;
    overflow: hidden;
    background-color: transparent;
    grid-area: media;
    width: 5.75rem;
    height: 5.75rem;

    @include mq(md) {
      display:block;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // block covers picture element padding issues
    display: block;
    margin: auto;
  }
  &__category {
    font-size: 0.875rem;
    font-weight: $fontWeightBold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 0;
    grid-area: meta;
  }
  &__title {
    color: var(--fill-blue);
    font-size: 1.5rem;
    line-height:1.1;
    margin-bottom: 0;
    font-weight: $fontWeightBold;
    grid-area: title;

    @include mq(md) {
      line-height:1.25;
    }
  }
  &__copy {
    grid-area: copy;
    margin:.5rem 0;

    @include mq(md) {
      margin:1.5rem 0;
    }
  }
  &__cta {
    display: flex;
    flex-direction: column;
    padding-top: .175rem;
    font-style: normal;
    border-top: 1px solid var(--fill-cool-gray);
    gap: .25rem;
    grid-area: cta;

    .event-list-item__title + & {
      margin-top:1.5rem;
    }

    @include mq(md) {
      gap:.75rem;
      padding-top: 1.3125rem;
    }

    i {
      font-size: 1.25em;
      width: 1.25rem;
      align-self: start;
      &:is(.glyph-clock) {
        font-size: 1em;
      }
    }
  }
  &__date {
    font-size: 1.125rem;
    font-weight: $fontWeightBold;
  }
  &__time,
  &__location {
    display: flex;
    align-items: center;
    position: relative;
    gap: .5rem;
    margin-bottom: 0;
  }
  &__time {
    font-size: 1rem;

    @include mq(md) {
      font-size: 1.25rem;
    }
  }
  &__location {
    font-style: normal;
    line-height:1.1;

    a {
      display:inline-block;
      margin-bottom:.125rem;
    }

    @include mq(md) {
      line-height:1.5;
    }
  }
  &__multi-day {
    display: inline-block;
    font-size: 13px;
    text-transform: lowercase;
    background: $warmGray;
    color: $brandBlue;
    padding: 0.3725em 0.675em 0.4em;
    position: relative;
    top: -2px;
    line-height: 1;
    border-radius: 0.5em;
    font-weight: bold;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px #fff;
  }
  &__multi-day--list {
    margin-left: 1rem;
    background: var(--fill-white);
  }
}

  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    :is(.section__rail) {
  .feature {
    display: flex;
    flex-direction: column;
    transform: none;
    gap: .5rem;
    padding-top: 0;
    &__content {
      padding: 2rem;
    }
    &__media {
      display: none;
    }
    &--card {
      .feature__media + .feature__content {
        transform: none;
      }
    }
    &--flipped {
      .feature__content {
        padding: 2rem;
      }
    }
  }
}
:is(.grid__item) {
  .feature {
    padding-top: 0;
  }
}
:is(.section--split .section__main) {
  .feature {
    &__content {
      padding: 2rem;
    }
    &--flipped {
      .feature__content {
        padding-right: 6rem;
      }
    }
    &:not(.feature--flipped, .feature--card, .feature--nav) {
      .feature__content {
        padding-left: 6rem;
      }
    }
  }
}
.feature {
  $featureCorner: 0.625rem;
  $contentPadLeft: 9.0625rem;
  $contentPadTopSm: 4.6875rem;
  $featurePadTop: 3rem;
  $featureMax: 85.375rem;
  display: flex;
  flex-direction: column;
  padding-top: $featurePadTop;
  max-width: $featureMax;
  @include mq(md) {
    display: grid;
    grid-template-columns: 49% 51%;
    transform: translateX(calc(($contentPadLeft * .25) * -1));
  }
  :where(.grid--match-height) &{
    display: flex;
    .feature__content {
      height: auto;
      align-self: stretch;
      flex: 1 1 100%;
    }
    .feature__media {
      + .feature__content {
        align-self: auto;
        flex: auto;
      }
    }
  }
  &:not(.feature--flipped) {
    .feature__media {
      @include mq(md) {
        grid-column-start: 1;
      }
    }
    .feature__content {
      @include mq(md) {
        grid-column-start: 2;
      }
    }
  }
  &--flipped {
    @include mqdn(md) {
      flex-direction: column-reverse;
    }
    @include mq(md) {
      transform: translateX(calc(($contentPadLeft * .25)));
    }
    .feature__media {
      transform: translateY(calc(($contentPadTopSm *.5)*-1));
      @include mqdn(md) {
        flex-direction: column-reverse;
      }
      @include mq(md) {
        grid-column-start: 2;
        transform: translate(calc(($contentPadLeft * .5)*-1), calc($featurePadTop * -1));
      }
    }
    .feature__content {
      padding: 2.125rem 1.5625rem $contentPadTopSm;
      @include mq(md) {
        padding: 4.3125rem $contentPadLeft 3.1875rem 4.375rem;
        grid-column-start: 1;
      }
    }
    .feature__caption {
      @include mq(md) {
        text-align: right;
      }
    }
  }

  &--card,
  &--nav {
    @include mq(md) {
      display: flex;
      flex-direction: column;
    }
    .feature__content {
      // background: var(--fill-cool-gray);
      color: $fontColorBase;
    }
  }
  &--card {
    transform: none;
    align-items: center;
    .feature__media {
      min-height: 0;
      transform: none;
      max-width: none;
      @include mqdn(md) {
        width: auto;
        margin: auto;
      }
      + .feature__content {
        width: 87%;
        transform: translateY(-4rem);
        align-items:start;
        text-align: left;
        .feature__cta {
          justify-content: start;
        }
      }
    }

    .feature__content {
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
      // border: 1px solid var(--fill-cool-gray);
      background-color: var(--fill-white);
      padding: 1.3125rem 1.8125rem;
      justify-content: start;
      gap: 0.9375rem;
      align-items: center;
      text-align: center;
    }
    .feature__title {
      font-family: $fontFamilyBase;
      margin-bottom: 0;
    }
    .feature__cta {
      border-top: 1px solid #ffffff50;
      // color: $linkColor;
      padding-top: 1rem;
      justify-content: center;
      a:not(.btn) {
        color: var(--fill-blue);
        font-weight: bold;
        &:after{
          content:'\2192';
          margin-left: .5rem;
        }
      }
    }
    &:is(a) {
      cursor: pointer;
  
      &:hover {
        text-decoration: none;
  
        .feature__image {
          opacity: .9;
        }
      }
  
      &:focus {
        outline: none;
      }
    }
  }

  &--nav {
    max-width: 100%;
    gap: 1.5rem;
  }
  @each $name, $color in $fills {
    &.u-background--fill-#{'' + $name} {
      // related to the tan color debacle
      background-color: unset !important;
      color: inherit;
      .feature__content {
        background-color: $color;
        color: choose-contrast-color($color);
      }
      .feature__caption {
        @include mqdn(md) {
          color: choose-contrast-color($color);
        }
      }
    }
    &.u-background--fill-ruby,
    &.u-background--fill-blue {
      .feature__cta a:not(.btn) {
        color: choose-contrast-color($color);
        &:after {
          color: choose-contrast-color($color);
        }
      }
      .btn--secondary{
        background-color: choose-contrast-color($color);
        color: var(--fill-#{$name});
        &.btn--ghost {
          border-color: choose-contrast-color($color);
          background-color: transparent;
          color: choose-contrast-color($color);
        }
      }
    }
  }
  // related to the tan color debacle
  &:is(.u-background--fill-tan) {
    .feature__content {
      background-color: var(--fill-warm-gray);
    } 
  }
  &__media,
  &__content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    @include mq(md) {
      grid-column-end: span 1;
      grid-row: 1 / span 1;
    }
  }

  &__media {
    
    position: relative;
    max-width: 41.875rem;
    transform: translateY(calc($contentPadTopSm *.5));
    
    margin-bottom: 0;
    @include mqdn(lg) {
      max-height: 25.4375rem;
    }
    @include mq(xl) {
      min-height: 100%;
    }
    @include mq(md) {
      transform: translate(calc($contentPadLeft * .5), calc($featurePadTop * -1));
    }
    @include mqdn(md){
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
      max-width: none;
      width: 100vw;
    }
    .feature--nav & {
      min-height: 0;
    }
    // &::before {
    //   content: '';
    //   display: block;
    //   position: relative;
    //   padding-top: calc((377 / 670) * 100%);
    //   pointer-events: none;
    // }
  }
  &__play {
    $size: 3rem;
    @include clean();
    height: $size;
    width: $size;
    position: absolute;
      // needs an offset
      top: calc($size * -1);
      right: 0;
      bottom: 0;
      left: 0;
    margin: auto;
    font-size: $size;
    color: var(--fill-white);
    &:hover,
    &:focus {
      color: var(--fill-white);
      i {
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  &__image,
  &__video {
    // position: absolute;
    // inset: 0;
    // object-fit: cover;
    // object-position: 50% 50%;
    // height: 100%;
    // width: 100%;
    // border: none;
    // transition: 0.2s ease;
    // max-width: 100%;

    * {
      max-width: 100%;
      border-radius: $featureCorner;
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    * {
      // object-fit: cover;
      // object-position: 50%;
      // max-width: 100%;
      // height: 100%;
      // width: 100%;
    }
  }
  &__video {
    border-radius: $featureCorner;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    aspect-ratio: 16/9;
  }
  &__caption {
    font-size: 0.875rem;
    text-decoration: none;
    @include mqdn(md) {
      margin-right: 3rem;
      text-align: right;
    }
  }
  &__content {
    align-items: flex-start;
    justify-content: center;
    border-radius: $featureCorner;
    background-color: var(--fill-cool-gray);
    padding: $contentPadTopSm 1.5625rem 2.125rem;
    max-width: 48rem;
    
    @include mq(md) {
      padding: 4.3125rem 4.375rem 3.1875rem $contentPadLeft;
    }
    .feature--nav & {
      padding: 0;
      background: none;
      color: inherit;
    }
  }

  &__title {
    // font-size: $h3Size;
    // font-family: $fontFamilyHeadings;
    // line-height: $h3SizeLineHeight;
    margin: 0 0 0.75rem;
  }

  &__copy {
    @include wysiwyg;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0;
    line-height: 1.5;

    & > * {
      margin: 0;
    }
  }

  &__cta {
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0 0;
    gap: 1rem;
    width: 95%;
    align-items: center;
    // justify-content: center;
    .btn {
      min-width: auto;
      max-width: 12.5rem;
    }
    .btn--ghost.btn--secondary {
      border-color: var(--fill-tan);
    }
    a:not(.btn) {
      color: currentColor;
    }
    @include mqdn(md) {
      flex-wrap: wrap;
      .btn {
        flex: 1 1 100%;
      }
    }
  }
}

  
.cms-message {
  width: 100%;
  border: solid 2px;
  display: flex;
  background: $white;

  &__icon-area {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid;
    min-height: 3.25rem;
    min-width: 3.25rem;
    &::before {
      @include icon;
      color: $white;
      font-weight: 700;
      font-size: 1.75rem;
      text-shadow: 0 0 6px rgba($black, 0.25), 0 0 12px rgba($black, 0.05);
    }
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    color: $black;
    & p {
      margin: 0;
    }
  }

  &--my1 {
    margin: 1rem 0;
    margin-bottom: 1rem !important;
  }
  &--my2 {
    margin: 2rem 0;
    margin-bottom: 2rem !important;
  }
  &--my3 {
    margin: 3rem 0;
    margin-bottom: 3rem !important;
  }
  &--my4 {
    margin: 4rem 0;
    margin-bottom: 4rem !important;
  }

  &--success {
    border-color: $success;
    & .cms-message__icon-area {
      background: $success;
      border-color: $success;
      &::before {
        content: '\e919';
      }
    }
  }
  &--warning {
    border-color: $warning;
    & .cms-message__icon-area {
      background: $warning;
      border-color: $warning;
      &::before {
        content: '\e900';
      }
    }
  }
  &--error {
    border-color: $error;
    & .cms-message__icon-area {
      background: $error;
      border-color: $error;
      &::before {
        content: '\e914';
      }
    }
  }
}

@mixin z-layer($key, $delta: false) {
  z-index: z-index($key, $delta);
}

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin mqdn($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: #{$breakpoint-value - 1}) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin mqDn($breakpoint) {
  @include mqdn($breakpoint);
}

@mixin mqminmax($minBreakpoint, $maxBreakpoint) {
  // check min break point
  @if map-has-key($breakpoints, $minBreakpoint) {
    $breakpoint-min-value: map-get($breakpoints, $minBreakpoint);
    // check max
    @if map-has-key($breakpoints, $maxBreakpoint) {
      $breakpoint-max-value: map-get($breakpoints, $maxBreakpoint);
      @media (min-width: $breakpoint-min-value) and (max-width: $breakpoint-max-value) {
        @content;
      }
    } @else {
      @warn 'Invalid maximum breakpoint: #{$maxBreakpoint}.';
    }
  } @else {
    @warn 'Invalid minimum breakpoint: #{$minBreakpoint}.';
  }
}

@mixin mqMinMax($minBreakpoint, $maxBreakpoint) {
  @include mqminmax($minBreakpoint, $maxBreakpoint);
}

@mixin container($max: $maxWidth, $margin: 2rem) {
  @content;
  margin-right: $margin;
  margin-left: $margin;
  width: calc(100% - #{$margin * 2});
  @if $max == none {
    max-width: 100%;
  } @else {
    max-width: $max;
    @media (min-width: #{$max + ($margin*2)}) {
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }
  }
}
@mixin twelveColumnGrid() {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1rem;
}
// Composition Vertical Padding Small
@mixin verticalPaddingSm {
  // padding-top: $gutter;
  // padding-bottom: $gutter;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

// Composition Vertical Padding
@mixin verticalPadding {
  padding-top: $gutter;
  padding-bottom: $gutter;

  @include mq(lg) {
    padding-top: $gutterLg;
    padding-bottom: $gutterLg;
  }
}

// Composition Vertical Padding Large
@mixin verticalPaddingLg {
  padding-top: $gutterLg;
  padding-bottom: $gutterLg;

  @include mq(lg) {
    padding-top: $gutterLg * 2;
    padding-bottom: $gutterLg * 2;
  }
}

// Composition Vertical Margin Small
@mixin verticalMarginSm {
  margin-top: $gutter;
  margin-bottom: $gutter;
}

// Composition Vertical Margin
@mixin verticalMargin {
  margin-top: $gutter;
  margin-bottom: $gutter;

  @include mq(lg) {
    margin-top: $gutterLg;
    margin-bottom: $gutterLg;
  }
}

// Composition Vertical Padding Large
@mixin verticalMarginLg {
  margin-top: $gutterLg;
  margin-bottom: $gutterLg;

  @include mq(lg) {
    margin-top: $gutterLg * 2;
    margin-bottom: $gutterLg * 2;
  }
}

@mixin background-fill($color) {
  color: choose-contrast-color($color);
  position: relative;
  z-index: 0;
  &::before {
    isolation: isolate;
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 100vw;
    transform: translateX(-50%);
    background-color: $color;
    mix-blend-mode: screen;
  }
}

@mixin background-image {
  position: relative;
  background-size: 0 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  z-index: 0;
  &::after {
    content: '';
    z-index: -2;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 100vw;
    transform: translateX(-50%);
    background-color: $white;
    background-image: inherit;
    background-size: cover;
    background-position: 50% 50%;
  }
}

/* 
create a fluid property value that adjusts from $min to $max, 
based on screen size (default $minWidth and $maxWidth in the _variables.scss)
example:
h1 {
  @include fluid(font-size, 2rem, 3rem);
  @include fluid(margin-bottom, 20px, 30px);
  @include fluid(padding-left, 0.5rem, 5rem, 900px, 1200px);
}
*/

@mixin fluid(
  $prop: false,
  $min,
  $max,
  $screenMin: $minWidth,
  $screenMax: $maxWidth
) {
  @if not $prop {
    @error 'Please define a fluid property.';
  }

  $u1: unit($min);
  $u2: unit($max);
  $u3: unit($screenMin);
  $u4: unit($screenMax);

  @if $u1 != $u2 {
    @error 'Property units need to match.';
  }
  @if $u3 != $u4 {
    @error 'Screen size units need to match.';
  }
  @if $u1 != rem and $u1 != px or $u3 != rem and $u3 != px {
    @error 'Only rem or px units are allowed';
  }

  @if $u1 == px {
    $min: math.div($min, $rootEM) * 1rem;
    $max: math.div($max, $rootEM) * 1rem;
  }
  @if $u3 == px {
    $screenMin: math.div($screenMin, $rootEM) * 1rem;
    $screenMax: math.div($screenMax, $rootEM) * 1rem;
  }
  & {
    #{$prop}: $min;
    @media screen and (min-width: $screenMin) {
      #{$prop}: calc(
        #{$min} + #{strip-unit($max - $min)} *
          ((100vw - #{$screenMin}) / #{strip-unit($screenMax - $screenMin)})
      );
    }
    @media screen and (min-width: $screenMax) {
      #{$prop}: $max;
    }
  }
}

//hide visually but leave for screenreaders
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

@mixin icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media speech {
    display: none;
    visibility: hidden;
  }
}

@mixin wysiwyg {
  display: flow-root;
  //floating images
  p img {
    margin: 0 0 1rem;
    display: block;

    @include mq(md) {
      float: right;
      margin: 1rem 0 1rem 1rem;
    }
  }

  img {
    margin: 1rem 0;
  }

  //definition/description list
  dl {
    display: flex;
    flex-flow: row wrap;
    justify-items: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-bottom: 1rem;
    opacity: 0.8;
    & + dl {
      margin-top: -1rem;
    }
  }

  dt,
  dd {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    padding: 0.5rem 0.75rem;
    margin: 0;
    position: relative;
  }

  dt {
    font-weight: 700;
    font-size: 1em;
    padding-right: 0;
    & + dd {
      padding-left: 0.5rem;
    }
    &:only-of-type {
      min-width: 115px;
      width: 115px;
    }
  }

  dd {
    font-size: 1em;
    &:not(:last-child)::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      max-height: 2ch;
      margin: auto 0;
      background-color: palette(gray, 400);
    }
  }

  //blockquote
  blockquote {
    margin-right: 0;
    margin-left: 0;

    p {
      font-weight: 400;
      font-family: $fontFamilyHeadings;
      color: palette(gray, 900);
      margin: 0 0 1.5rem;
      &::before {
        content: open-quote;
      }
      &::after {
        content: close-quote;
      }
    }

    cite {
      font-style: normal;
      font-size: 1rem;
      font-weight: 700;
      display: block;

      @include mq(lg) {
        display: inline-block;
      }
    }
  }

  //text styles
  strong,
  b {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  //links
  a {
    text-decoration: underline;
  }

  //list items
  li {
    margin-bottom: 0.5rem;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  @content;
}


    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    
.accordion {
  --toggle-size: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &.is-filtered { 
    .accordion__item {
      display: none;
      &.is-active {
        display: flex;
      }
    }
  }
  &--event {
    .accordion__trigger {
      justify-content: start;
      gap: 1rem;
      font-weight: 700;
      @include mqdn(sm) {
        display: flex;
        flex-direction: column;
        align-items: start;
      }
    }
    .accordion__trigger-label {
      font-size: .75em;
      appearance: none;
      all: unset;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding: .25em;
      cursor: pointer;
      text-align: center;
      color: var(--fill-blue);
      border: 1px solid var(--fill-blue);
      border-radius: $cornerRound;
      transition: 0.25s ease;
      font-weight: 400;
    }
  }
  &--nested {
    --toggle-size: 1rem;
    .accordion__trigger {
      padding: 0;
      font-size: 1rem;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--fill-cool-gray);
    padding-top: 1rem;
  }

  &__trigger-header {
    font-weight: bold !important;
  }
  
  &__trigger {
    all: unset;
    appearance: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 2.25rem 0.5rem 1.25rem;
    margin: 0;
    line-height: 1;
    font-size: 1.25rem;
    cursor: pointer;
    position: relative;
    z-index: 0;
    &::before,
    &::after {
      height: var(--toggle-size);
      width: var(--toggle-size);
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-top: 1px solid;
    }
    &::before {
      content: '';      
      transform: translateY(calc(var(--toggle-size) * .5));
    }

    &::after {
      content: '';
      transform: translateX(calc((var(--toggle-size) * .5) * -1 )) rotate(90deg);
      // transition: 0.2s ease;
    }

    &[data-active] {
      &::after{
        transform: translateY(calc(var(--toggle-size) * .5)) rotate(0);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    
  }

  &__content-inner {
    padding: 1rem 1.25rem .5rem;
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}
  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .services-feature {
  $featureCorner: 0.625rem;
  $contentPadLeft: 9.0625rem;
  $contentPadTopSm: 4.6875rem;
  $featurePadTop: 3rem;
  display: flex;
  padding: 2rem 0;
  max-width: $maxWidth;
  position: relative;
  gap: 4.5rem;
  align-items: center;
  color: var(--fill-black);
  + & {
    margin-top: $paddingLg;
  }
  @include mqdn(md) {
    flex-direction: column;
    gap: 2rem;
  }
  &--flipped {
    @include mq(md) {
      flex-direction: row-reverse;
      .services-feature__background {
        right: auto;
        left: 0;
      }
    }
  }
  &__background {
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    height: 100%;
    z-index: -1;
    background-color: var(--fill-cool-gray);
    border-radius: $featureCorner;
    @include mqdn(md) {
      width: 100%;
      height: 90%;
      bottom: 0;
      top: auto;
    }
  }
  &__media,
  &__content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  &__media {
    position: relative;
    margin-bottom: 0;

    // &::before {
    //   content: '';
    //   display: block;
    //   position: relative;
    //   padding-top: calc((377 / 670) * 100%);
    //   pointer-events: none;
    // }
  }

  &__image,
  &__video {
    // position: absolute;
    // inset: 0;
    // object-fit: cover;
    // object-position: 50% 50%;
    // height: 100%;
    // width: 100%;
    // border: none;
    // transition: 0.2s ease;
    // max-width: 100%;

    * {
      max-width: 100%;
      border-radius: $featureCorner;
    }
  }

  &__image {
    * {
      // object-fit: cover;
      // object-position: 50%;
      // max-width: 100%;
      // height: 100%;
      // width: 100%;
    }
  }
  &__video {
    border-radius: $featureCorner;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  // &__caption {
  //   font-size: 0.875rem;
  //   @include mqdn(md) {
  //     margin-right: 3rem;
  //     text-align: right;
  //   }
  // }
  &__content {
    align-items: flex-start;
    justify-content: center;
    border-radius: $featureCorner;
    // background-color: var(--fill-cool-gray);
    padding: 1rem;
    max-width: 48rem;
    gap: .5rem;
    @each $name, $color in $fills {
      &.u-background--fill-#{'' + $name} {
        color: choose-contrast-color($color);
        .btn--secondary{
          background-color: choose-contrast-color($color);
          color: var(--fill-#{$name});
          &.btn--ghost {
            border-color: choose-contrast-color($color);
            background-color: transparent;
            color: choose-contrast-color($color);
          }
        }
      }
    }
    @include mq(md) {
      flex: 1;
    }
  }
  &__category {
    font-size: 0.875rem;
    font-weight: $fontWeightHeadings;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 0;
  }
  &__title {
    color: var(--fill-blue);
    font-size: 2.25rem;
    font-family: $fontFamilyHeadings;
    line-height: 1.25;
    margin: 0 0 0.75rem;
    @include mqdn(md) {
      font-size: clamp(1.75rem, 5vw, 2.25rem);
    }
  } 

  &__copy {
    @include wysiwyg;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0 0 1.25rem;
    line-height: 1.5;
    & > * {
      margin: 0;
    }
  }
  &__date {
    font-size: 1.1rem;
    border-top: 1px solid var(--fill-warm-gray);
    width: 100%;
    padding-top: 1.25rem;
    font-weight: $fontWeightHeadings;
  }
  &__location {
    font-style: normal;
    display: flex;
    flex-direction: column;
    padding-left: 1.75rem;
    position: relative;
    
    &::before {
      content: url(img/template-images/pin.svg);
      position: absolute;
        left: 0;
        top: 0;
    }
  }
  &__cta {
    font-size: 1rem;
    display: flex;
    margin: 0.5rem 0 0;
    gap: 1rem;
    width: 95%;
    a:not(.btn) {
      color: currentColor;
    }
    @include mqdn(md) {
      flex-wrap: wrap;
      .btn {
        flex: 1 1 100%;
      }
    }
  }
}

  
.composition {
  &:where(.composition--padded-sm) {
    @include verticalPaddingSm;
  }
  &:where(.composition--padded) {
    @include verticalPadding;
  }
  &:where(.composition--padded-lg) {
    @include verticalPaddingLg;
  }
  &:where(.composition--padded-flush-top) {
    padding-top: 0;
  }
  &:where(.composition--padded-flush-top-map) {
    padding-top: 0.03rem;
  }
  &:where(.composition--padded-flush-bottom) {
    padding-bottom: 0;
  }
  &:where(.composition--spaced-sm) {
    @include verticalMarginSm;
  }
  &:where(.composition--spaced) {
    @include verticalMargin;
  }
  &:where(.composition--spaced-lg) {
    @include verticalMarginLg;
  }
  &:where(.composition--spaced-flush-top) {
    margin-top: 0;
  }
  &:where(.composition--spaced-flush-bottom) {
    margin-bottom: 0;
  }
  &:where(.composition--article-list) {
      a {
        &:not(.article-card__cta){
          color:currentColor
        }
      }
  }
  &:where(.composition--full) {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    max-width: none;
    width: 100vw;
  }
  @each $name, $color in $fills {
    &:where(.composition--fill-#{'' + $name}) {
      @include background-fill($color);
    }
  }
  &:where([style*='background-image']) {
    @include background-image;
  }
}

// This is an override to accommodate client, SD, SL, and BE developer needs.
// Ideally tan is removed from the CMS and replaced with the appropriate warm gray
.composition--fill-tan {
  background-color: var(--fill-warm-gray);
  color: var(--fill-black);
  &:before{
    background-color: var(--fill-warm-gray);
    mix-blend-mode: unset;
  }
  *:not(.btn):not(a) {
    color: var(--fill-black);
  }
}
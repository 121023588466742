
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .section-header {
  @include fluid(margin-bottom, 2.25rem, 3.75rem);
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  justify-items: center;
  text-align: center;

  .composition--article-list & {
    margin-bottom:2.5rem;
    
    @include mq(md) {
      margin-bottom:4.25rem;
    }
  }

  &--left {
    justify-items: start;
    text-align: left;
  }

  &--right {
    justify-items: end;
    text-align: right;
  }
  &--sans {
    font-family: $fontFamilyBase;
    .section-header__title {
      font-family: inherit;
    }
  }
  * {
    max-width: 700px;
  }

  &__eyebrow {
    @include fluid(font-size, 0.75rem, 0.875rem);
    grid-area: span 1 / span 1;
    font-style: italic;
    margin: 0;
    line-height: 1.5;
    &:not(:last-child) {
      margin: 0 0 0.5rem;
    }
  }
  
  &__title {

    display: contents;
    margin: 0;
    grid-area: span 1 / span 1;
    &:not(:last-child)::after {
      @include fluid(margin-bottom, 1.125rem, 1.5rem);
    }
  }
  
  &__copy {
    @include fluid(font-size, 0.875rem, 1rem);
    grid-area: span 1 / span 1;
    line-height: 1.5;
    margin: 0;
  }
}
  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .document {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  background-color: var(--fill-cool-gray);
  width: 85%;
  margin: 0 auto 1rem;
  // &--vertical {
  //     align-items: center;
  //     flex-direction: column;
  // }
  // &--reverse {
  //     flex-direction: row-reverse;
  // }
  // &--center {
  //     align-items: center;
  // }
  @include mqdn(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__media {
    max-width: 120px;
    margin-bottom: 0;
    @include mqdn(sm) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__icon {
    font-size: 4rem;
    line-height: 1;
  }
  &__content {
    flex: 1;
  }
  &__link {
    font-size: $h4Size;
    display: flex;
    gap: .5rem;
    align-items: center;
    i {
      font-size: .65em;
      line-height: 0;
      @include mqdn(sm) {
        display: none;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__copy {
    margin-bottom: 0;
  }
}
  
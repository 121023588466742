
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    /* Main SCSS import from document styles, and global elements*/

/* 
  ***** NOTE: *****
  Both "_core/" & "global/" abstracts are imported 
  automatically so that they are present in every 
  SCSS block (Global SCSS/JS imported SCSS/Vue 
  SCSS Style Blocks)
*/

:root {
  @each $bp, $value in $breakpoints {
    --#{"" + $bp}: #{$value};
  }
  @each $name, $color in $fills {
    --fill-#{"" + $name}: #{$color};
  }
}

@import 'base';
@import 'layout';
@import 'objects';
@import 'vendor';
  
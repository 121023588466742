
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    blue-form {
  $label-color: #777;
  $input-color: #222;
  $border-color: #aaa;
  $placeholder-color: #999;
  $explanation-color: #757575;
  $active-color: #1e90ff;
  $error-color: #800;

  &,
  form,
  .ktc-default-section,
  .editing-form-control-nested-control,
  .editing-form-control-nested-control > span {
    display: flex;
    flex-direction: column;
  }

  form {
    align-self: center;
    width: 100%;
    max-width: 750px;
    gap: 1rem;
  }

  .ktc-default-section {
    gap: 1rem;
  }

  .editing-form-control-nested-control {
    gap: 0.25rem;

    [class*='field-validation']:empty {
      display: none;
    }
  }

  .form-field {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    position: relative;
    gap: 0.5rem;
    & > *:not(label, .blue-form__tooltip) {
      grid-area: span 1 / span 3;
    }
  }

  .form-field > label {
    grid-area: 1 / 1 / 2 / 2;
    font-weight: 700;
    color: var(--fill-gray);
    font-size: 0.875rem;
    cursor: pointer;
    transition: 0.1s ease;
    order: -1;
  }

  .blue-form__tooltip {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: var(--fill-gray);
    cursor: pointer;
    transition: 0.2s ease;
    padding-top: 2px;
    padding-right: 2px;

    &::before {
      content: '?';
      color: $white;
      line-height: 1;
      font-family: $fontFamilyHeadings;
      font-style: italic;
      font-size: 0.75rem;
    }

    &:hover {
      background: var(--fill-blue);
      span {
        opacity: 1;
        visibility: visible;
      }
    }

    span {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 100%;
      left: 0;
      width: max-content;
      max-width: 300px;
      padding: 1rem;
      margin-bottom: 0.75rem;
      pointer-events: none;
      z-index: 10;
      background: var(--fill-white);
      box-shadow: 0 0 0.5rem rgba(var(--fill-blue), 0.15),
      0 0 0.75rem rgba(var(--fill-blue), 0.05);
      opacity: 0;
      visibility: hidden;
      transition: 0.2s ease;
    }
  }

  input:not([type='checkbox'], [type='radio'], [type='file'], [type='submit']),
  textarea,
  select {
    appearance: none;
    all: unset;
    border: 1px solid var(--fill-warm-gray);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    width: auto;
    max-width: 100%;
    color: $input-color;
    line-height: 1.5;
    box-sizing: border-box;
    transition: 0.1s ease;
    &::placeholder,
    &.is-placeholder {
      color: $placeholder-color;
      font-style: italic;
    }
    &:focus {
      border-color: var(--fill-blue);
      box-shadow: 0 0 0.5rem rgba(var(--fill-blue), 0.15),
      0 0 0.75rem rgba(var(--fill-blue), 0.05);
    }
  }

  select {
    padding-right: 2rem;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    background-size: 0.5rem;
    background-position: calc(100% - 0.75rem) center;
    background-repeat: no-repeat;
    option {
      font-style: normal;
      color: var(--fill-black);
    }
  }

  input:is([type='checkbox']),
  input:is([type='radio']) {
    appearance: none;
    all: unset;
    position: absolute;
    height: 1px;
    width: 1px;
    opacity: 1;
    top: 0;
    left: 0;
    pointer-events: none;

    & + label {
      display: grid;
      grid-template-columns: 1rem 1fr;
      gap: 0.75rem;
      cursor: pointer;
      transition: 0.1s ease;
      line-height: 1.5;

      &::before {
        grid-area: 1 / 1 / 2 / 2;
        content: '';
        height: 1rem;
        width: 1rem;
        flex: 0 0 1rem;
        border: 1px solid var(--fill-black);
        cursor: pointer;
        transition: 0.1s ease;
        transform: translateY(4px);
      }
    }

    &:focus + label {
      &::before {
        border-color: var(--fill-blue);
        box-shadow: 0 0 0.5rem rgba(var(--fill-black), 0.2),
        0 0 0.75rem rgba(var(--fill-black), 0.05);
      }
    }

    &[type='radio'] {
      & + label {
        &::before {
          border-radius: 50%;
        }
      }

      &:checked + label {
        &::before {
          border-color: var(--fill-blue);
          background-image: radial-gradient(transparent 40%, var(--fill-blue) 45%);
        }
      }
    }

    &[type='checkbox'] {
      & + label {
        &::after {
          content: '';
          grid-area: 1 / 1 / 2 / 2;
          justify-self: center;
          height: 0.625rem;
          width: 0.375rem;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: translateY(5px) rotate(45deg);
          color: $white;
        }
      }

      &:checked + label {
        &::before {
          border-radius: 3px;
          border-color: var(--fill-blue);
          background: var(--fill-blue);
        }
      }
    }
  }

  .ktc-file-component-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid var(--fill-warm-gray);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    width: auto;
    max-width: 100%;
    color: $input-color;
    line-height: 1.5;
    position: relative;

    input:is([type='file']) {
      appearance: none;
      all: unset;
      position: absolute;
      height: 1px;
      width: 1px;
      opacity: 1;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    .ktc-uploader-text-button,
    .ktc-uploader-replacement {
      display: none;
    }

    label {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    .ktc-uploader-text {
      cursor: pointer;
      font-style: italic;
      font-size: 0.875rem;
    }

    .ktc-uploader-icon-button {
      cursor: pointer;
      font-size: 1.125rem;
      &[data-icon='select'] {
        color: var(--fill-warm-gray);
        &::before {
          @include icon;
          content: '\e95f';
        }
      }

      &[data-icon='loading'] {
        color: var(--fill-blue);
        @extend %glyph-loading;
      }

      &[data-icon='remove'] {
        color: var(--fill-ruby);
        &::before {
          @include icon;
          content: '\e914';
        }
      }
    }
  }

  .explanation-text,
  .field-validation-error {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 700;
    font-style: italic;
    font-size: 0.875rem;

    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-top: 1px;
      // padding-right: 2px;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      line-height: 1;
      font-family: $fontFamilyHeadings;
      font-style: italic;
      font-size: 0.75rem;
      color: $white;
    }
  }


  .explanation-text {
    color: var(--fill-gray);
    transform: translateY(-0.25rem);
    &::before {
      content: 'i';
      background: var(--fill-gray);
    }
  }

  .field-validation-error {
    color: var(--fill-ruby);
    &::before {
      content: '!';
      background: var(--fill-ruby);
    }
  }

  input:is([type='submit']) {
    @extend %btn;
    align-self: flex-start;
    margin-top: 1.5rem;
    transition: background 0.2s ease;
  }
}

  
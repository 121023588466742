// --------------------------------------------
// @font-face declarations
// --------------------------------------------

@font-face {
  font-family: 'icons';
  src: url('/fonts/icons/icons.woff') format('woff'),
    url('/fonts/icons/icons.ttf') format('truetype'),
    url('/fonts/icons/icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url("/fonts/librebaskerville/v14/librebaskerville.woff2") format('woff2');
  font-display: block;
  font-style: normal;
  font-weight: 400;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Libre Baskerville Italic';
  src: url("/fonts/librebaskerville/v14/librebaskervilleitalic.woff2") format('woff2');
  font-display: block;
  font-style: normal;
  font-weight: 400;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Libre Baskerville Bold';
  src: url("/fonts/librebaskerville/v14/librebaskervillebold.woff2") format('woff2');
  font-display: block;
  font-style: normal;
  font-weight: 700;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url("/fonts/nunitosans/v15/nunito_400.woff2") format('woff2');
  font-display: block;
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
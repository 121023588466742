
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .grant-chart {
  display: block;
  &__inner {
    border-top: 1px solid var(--fill-warm-gray); 
    border-bottom: 1px solid var(--fill-warm-gray);
    padding: 3rem 0;
    
    &.grid {
      gap: 1rem clamp(2rem, 25vw, 20%);
    }
    
  }
  &__item {
    display: flex;
    justify-content: space-between;
    gap: .5rem;
    span {
      text-align: right;
    }
  }
}
  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .header {
  $header-max-height-md: 5.9375rem;
  display: flex;
  flex-direction: column;
  background: var(--fill-white);
  color: var(--fill-black);
  z-index: 10;
  &.is-open {
    @include mqdn(md) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &__inner {
    
    display: flex;
    gap: 0 0.25rem;
    position: relative;
    // grid-template-areas:
    //   "logo logo-slug header-toggle";
    padding: 0 0 0 .5rem;
    justify-content: space-between;
    align-items: center;
    @include mq(md) {
      display: grid;
      grid-auto-flow: row;
      grid-template-rows: auto;
      grid-template-areas:
      "logo logo-slug header-util header-util"
      "logo ... ... ..."
      "main-nav main-nav main-nav nav-tools";
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: max-content 1fr 1fr 2.1875rem;
      @include container($margin: 1.5rem);
      padding: 1.5rem 0 .5rem;
    }
    @include mqdn(md) {
      // max-height: $header-max-height-md;
      flex-wrap: wrap;
      padding: 0;
    }
    @include mq(lg) {
      grid-template-areas:
      "logo logo-slug header-util header-util"
      "logo main-nav main-nav nav-tools";
      grid-template-rows: 1fr 1fr;
      gap: 2rem 0.25rem;
      &--search-is-active {
        grid-template-areas:
        "logo logo-slug header-util header-util"
        "logo nav-tools nav-tools nav-tools";
        .header__nav {
          display: none;
        }
      }
    }
  }

  &__logo {
    display: flex;
    grid-area: logo;
    max-width: 370px;
    position: relative;
    @include mq(lg) {
      padding-bottom: 1rem;
    }
    @include mqdn(sm) {
      padding-left: .5rem;
      flex-basis: 20%;
    }
    &:after,
    &:before {
      @include mqdn(md) {
        display: none;
      }
      content:'';
      position: absolute;
      height: 1px;
      pointer-events: none;
      bottom: 37%;
      background-color: var(--fill-warm-gray);
      z-index: 1;
    }
    &:before {
      left: -50vw;
      width: 50vw;
    }
    &:after {
      left: 0;
      width: 100vw;
    }
    @include mqdn(md) {
      flex: 1 0 auto;
      max-width: 4.6875rem;
    }
    &-link {
      display: flex;
      align-items: center;
      height: auto;
      width: auto;
      border-radius: 50%;
      background-color: var(--fill-white);
      z-index: 2;
      @include mq(md) {
        padding: 0 .75rem;
      }
    }

    &-img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__slug {
    grid-area: logo-slug;
    
    margin-left: .25rem;
    color: var(--fill-tan);
    font-size: 1.25rem;
    text-transform: uppercase;
    font-family: $fontFamilyHeadings;
    font-weight: 600;
    @include mq(md) {
      align-self: end;
    }
    @include mqdn(md) {
      font-size: 1rem;
    }
    @include mqdn(sm) {
      flex-basis: 45%;
    }
    span {
      font-family: $fontFamilyHeadings;
      text-transform: lowercase;
      font-style: italic;
      @include mqdn(md) {
        display: inline-block;
      }
    }
  }

  &__util {
    display: none;
    justify-content: flex-end;
    grid-area: header-util;
    position: relative;
    z-index: 2;
    background: var(--fill-white);

    @include mq(md) {
      display: flex;
    }

    &-list {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      gap: 1rem;
      align-items: center;
    }

    &-item {
      display: flex;
      position: relative;
      height: max-content;
      
      @include mq(md) {
        body:not(.using-mouse) &:focus-within {
          .header__util-label {
            #{$focusProperty}: $focusValue;
          }
        }
      }
    }

    &-label {
      @extend %btn;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: var(--fill-white);
      background-color: var(--fill-ruby);
      transition: 0.2s ease;
      padding: 0;
      align-self: center;
      text-transform: initial;
      font-weight: 800;
      min-width: auto;
      @include mq(md) {
        align-self: stretch;
        font-size: 0.875rem;
        padding: 0.75rem 1.5rem;
      }
      &--ghost {
        @extend %btn--ghost;
        background-color: transparent;
        border-color: var(--fill-tan);
        color: var(--fill-ruby);
      }
    }

    &-menu {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      position: absolute;
      top: 100%;
      right: 0;
      min-width: 100%;
      z-index: -1;
      overflow: hidden;
      transform: translateY(-1rem);
      opacity: 0;
      visibility: hidden;
      transition: 0.2s ease;
      pointer-events: none;
      background: $coolGray;
      padding: 1rem;
      box-shadow: 0 0 0.25rem rgba($warmGray, 0.35),
        0 0 0.5rem rgba($warmGray, 0.05);

      .is-active & {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: translateY(0);
      }

      &-link {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        align-items: center;
        font-size: 0.75rem;
        text-transform: uppercase;
        color: black;
        white-space: nowrap;
        margin-left: -0.75rem;
        margin-right: -0.25rem;
        border-left: 0.375rem solid transparent;
        padding-left: 0.375rem;
        padding-right: 0.25rem;

        &:focus,
        &:hover {
          border-left-color: $blue;
          color: $warmGray;
          text-decoration: none;

          i::before {
            transform: translateX(0.5rem);
          }
        }

        i::before {
          transition: 0.2s ease;
        }
      }
    }
  }

  &__toggle {
    
    grid-area: header-toggle;
    display: flex;
    flex-direction: column;
    justify-self: end;
    align-items: center;
    align-self: center;
    justify-content: center;
    cursor: pointer;
    appearance: none;
    border: none;
    background-color: var(--fill-warm-gray);
    padding: 1rem;
    // height: 1.75rem;
    // width: 1.75rem;
    max-width: 4.5rem;
    margin-left: 0.25rem;
    position: relative;
    @include mq(md) {
      display: none;
    }
    &-button {
      @include clean();
      display: flex;
      flex-direction: column;
      gap: .5rem;
      i {
        margin: auto;
        &::before {
          display: block;
          font-size: 2.25rem;
          color: var(--fill-gray);
          transition: 0.2s ease;
        }
      }
      &:last-of-type {
        // font-size: 1.5rem;
        display: none;
      }
    }
    body:not(.using-mouse) &:focus {
      #{$focusProperty}: none;
      box-shadow: inset 0 0 0 0.125rem $blue;
    }

    &:hover {
      .header__toggle-button i::before {
        color: inherit;
        
      }
    }

    

    &.is-open {
      .header__toggle-button {
        // &::before {
        //   transform: rotate(180deg);
        // }

        &:first-of-type {
          opacity: 0;
          display: none;
        }

        &:last-of-type {
          display: flex;
          opacity: 1;
        }
      }
    }
  }

  &__nav {
    display: none;
    background-color: var(--fill-white);
    
    @include mq(md) {
      grid-area: main-nav;
      display: flex;
      flex-direction: column;
      position: relative;
      // &:after {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 50%;
      //   bottom: 0;
      //   // No idea why this needs 105 and 52
      //   width: 105vw;
      //   transform: translateX(-52%);
      //   background: inherit;
      //   z-index: -1;
      // }
    }

    @include mq(lg) {

      &::before {
        display: none;
      }
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 0 3vw;
      @include mq(md) {
        display: flex;
        flex: 1 1 100%;
        justify-content: start;
      }
      @include mq(lg) {
       
        gap: 4.5rem;
      }
    }

    &-item {
      @include mq(md) {
        display: flex;

        &--single {
          position: relative;
        }
      }
    }

    &-label {
      @include mq(md) {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        appearance: none;
        border: none;
        background: transparent;
        font-size: 1.125rem;
        color: var(--fill-black);
        transition: 0.2s ease;
        padding: 0.5rem 0;

        .is-active &,
        body:not(.using-mouse) &:focus {
          #{$focusProperty}: unset;

          span {
            // #{$focusProperty}: $focusValue;
            background-color: var(--fill-warm-gray);
            border-radius: $cornerRound;
          }
        }
      }

      @include mq(lg) {
        padding: 0;
      }

      span {
        @include mq(md) {
          display: flex;
          align-items: center;
          position: relative;
          padding: 0.25rem;
          gap: 0.75rem;
        }

        @include mq(lg) {
          padding: .75rem 0.5rem;
        }

        &::after {
          @include mq(md) {
            $caretWidth: 0.875rem;
            $caretHeight: calc($caretWidth * .5);
            content: '';
            background-image: url('img/template-images/caret.svg');
            background-size: cover;
            background-repeat: no-repeat;
            height: $caretHeight;
            width: $caretWidth;
            transform: rotate(180deg) translateY(-2px);
            transition: 0.2s ease;

            .is-active & {
              opacity: 1;
              transform: rotate(0);
            }
          }
        }
      }
      // menu arrow
      // &::before {
      //   @include mq(md) {
      //     content: '';
      //     position: absolute;
      //     bottom: -1px;
      //     left: 50%;
      //     transform: translateX(-50%) translateY(-1rem);
      //     height: 0;
      //     width: 0;
      //     border-left: 0.7rem solid transparent;
      //     border-right: 0.7rem solid transparent;
      //     border-bottom: 0.6rem solid var(--fill-white);
      //     opacity: 0;
      //     transition: 0.2s ease;

      //     .is-active & {
      //       opacity: 1;
      //       transform: translateX(-50%);
      //     }
      //   }
      // }
    }

    &-menu {
      @include mq(md) {
        display: flex;
        position: absolute;
        top: 100%;
        left: 50%;
        color: var(--fill-black);
        z-index: -1;
        overflow: hidden;
        transform: translateX(-50%) translateY(-1rem);
        opacity: 0;
        visibility: hidden;
        transition-property: opacity, visibility, transform;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        pointer-events: none;
        background: var(--fill-white);
        padding: 1.5rem 2rem;
        border-top: 1px solid var(--fill-warm-gray);
        box-shadow: 0px 0.625rem 0.8125rem 0px rgb(0 0 0 / 25%);
        .is-active & {
          opacity: 1;
          visibility: visible;
          // again no idea why -52% is needed
          transform: translateX(-50%);
          pointer-events: all;
        }

        .header__nav-item--full & {
          width: 100vw;
          // max-width: 1000px;
        }

        .header__nav-item--feature & {
          width: 100%;
          max-width: 700px;
        }
      }
    }

    &-back {
      @include mq(md) {
        display: none;
      }
    }

    &-grid {
      @include mq(md) {
        width: calc(100% + 0.5rem);
        margin: -0.25rem;
        padding: 0.25rem;
      }

      .header__nav-item--full & {
        @include mq(md) {
          @include container($margin: 1.5rem);
          display: grid;
          grid-auto-rows: 2px;
          justify-content: center;
          grid-auto-flow: column;
          position: relative;
          z-index: 0;
          overflow: hidden;
          grid-template-columns: repeat(3,1fr);
          gap: 1rem 2rem;
          
        }

        @include mq(lg) {
          grid-auto-flow: row;
        }
      }

      .header__nav-item--feature & {
        @include mq(md) {
          display: flex;
        }
      }

      &-cell {
        @include mq(md) {
          display: flex;
          flex-direction: column;
          padding: 0;
          margin: 0;

          .header__nav-item--feature & {
            flex: 1 0 auto;
            padding: 0 2rem;
            border-right: 1px solid darken($coolGray, 5%);

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              border-right: none;
              padding-right: 0;
              flex: 0 1 450px;
            }
          }

          .header__nav-item--full & {
            position: relative;
            padding-right: 1rem;

            &:not(.is-last-column)::after {
              content: '';
              position: absolute;
              top: -100vh;
              right: -1rem;
              height: 200vh;
              width: 1px;
              background: darken($coolGray, 5%);
              z-index: -2;
            }
          }
        }
      }

      &-content {
        @include mq(md) {
          display: flex;
          font-size: 1rem;
        }
      }
    }

    &-group {
      @include mq(md) {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
      }

      &-title {
        appearance: none;
        border: none;
        background: none;
        text-align: left;

        @include mq(md) {
          text-transform: uppercase;
          margin: 0 0 1.5rem 0;
          // color: $warmGray;
          padding: 0;

          &:is(a) {

            &:focus,
            &:hover {
              text-decoration: none;
              color: $blue;
            }
          }
        }
      }

      &-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style: none;

        @include mq(md) {
          gap: 0.5rem;
        }
      }

      &-item {
        display: flex;
        margin: 0;
        padding: 0;

        @include mq(md) {
          line-height: 1.5;

          &--feature {
            white-space: nowrap;
          }
        }
      }

      &-link {
        @include mq(md) {
          margin: 0;
          padding: 0;
          // color: $warmGray;
          margin-left: -1rem;
          margin-right: -0.25rem;
          border-left: 0.5rem solid transparent;
          padding-left: 0.5rem;
          padding-right: 0.25rem;
          transition: 0.2s ease border-left;

          .header__nav-item--single & {
            white-space: nowrap;
          }

          &:focus,
          &:hover {
            border-left-color: var(--fill-blue);
            color: $linkColor;
            text-decoration: none;
            font-weight: 600;
            .header__nav-group-desc {
              color: var(--fill-black);
              font-weight: 400;
            }
          }
        }
      }
      &-desc {
        color: var(--fill-black);
        display: none;
        margin: .5rem 0 1rem auto;

        @include mq(md) {
          display:block;
        }
      }
    }
  }
  &__drawer {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--fill-white);
    color: var(--fill-black);
    transform: translateX(100%);
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-property: transform, opacity, visibility;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    border-top: 1px solid var(--fill-gray);

    &.is-open {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
      margin-top: -6px;
      height: calc(100% - $header-max-height-md + 2px);
    }

    @include mq(md) {
      display: none;
    }

    &-inner {
      display: flex;
      flex: 0 0 100%;
      position: relative;
    }

    &-list {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      flex: 0 0 100%;
      overflow-y: auto;
    }

    &-item {
      display: flex;
      font-size: 0.875rem;
      position: static;
      border-bottom: 1px solid var(--fill-gray  );
    }

    .header {

      &__util,
      &__nav {
        &-back {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          appearance: none;
          border: none;
          background: var(--fill-blue);
          padding: 1rem 1.5rem;
          color: $white;
          justify-content: start;
          text-align: left;
          font-size: 1.25rem;
          cursor: pointer;
          width: 100%;

          &::before {
            content: '';
            height: 0.6rem;
            width: 0.6rem;
            border-left: 2px solid $white;
            border-top: 2px solid $white;
            transform: translateY(-15%) rotate(-45deg);
          }

          body:not(.using-mouse) & {
            &:focus {
              #{$focusProperty}: none;
              box-shadow: inset 0 0 0 0.125rem $blue;
            }
          }

          span {
            pointer-events: none;

            &.header__util-back-link {
              text-transform: uppercase;
              font-size: 0.75rem;
              margin-left: auto;
              color: $blue;

              &:hover {
                text-decoration: none;
              }
            }
          }
        }
        &-back {
          a {
            color: currentColor;
            font-size: .75rem;
          }
        }
        &-item,
        &-group {
          &.is-active {

            &>.header__nav-menu,
            &>.header__util-menu,
            &>.header__nav-group-list {
              transform: translateX(0);
              opacity: 1;
              visibility: visible;
            }
          }
        }
        &-label {

        }
        &-label,
        &-menu-link,
        &-group-title,
        &-group-link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          appearance: none;
          border: none;
          background: transparent;
          color: var(--fill-black);
          padding: 1rem 1.75rem 1rem 1.5rem;
          margin: 0;
          cursor: pointer;
          font-size: 1.15rem;
          text-transform: none;

          body:not(.using-mouse) & {
            &:focus {
              #{$focusProperty}: none;
              box-shadow: inset 0 0 0 0.125rem $blue;
            }
          }

          i {
            display: none;
          }

          &:hover {
            text-decoration: none;
            color: $white;
            color: $blue;
          }

          &:where(.header__nav-label),
          &:where(.header__util-label),
          &:where(.header__nav-group-title) {
            &::after {
              content: '';
              height: 0.6rem;
              width: 0.6rem;
              border-right: 2px solid currentColor;
              border-bottom: 2px solid currentColor;
              transform: translateY(-25%) rotate(-45deg);
            }
          }
        }

        &-menu,
        &-group-list:is(.header__nav-item--full *) {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          background: $warmGray;
          transition: 0.2s ease;
          transform: translateX(100%);
          opacity: 0;
          visibility: hidden;
          overflow-y: auto;
          gap: 0;
          padding: 0;
          box-shadow: none;
        }

        &-grid,
        &-grid-cell {
          display: contents;
        }

        &-grid-cell--feature {
          display: none;
        }
      }

      &__util {
        &-item {
          border-top: 1px solid $warmGray;

          &:last-child {
            border-bottom: 1px solid $warmGray;
          }

          a+button {
            display: none;
          }
        }
      }
    }
  }
}
  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .list-tools {
  background-color: var(--fill-cool-gray);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid $coolGray;
  flex-wrap: wrap;
  border-radius: 0.9375rem;
  &__item {
    background-color: var(--fill-white);
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    gap: 0.25rem;
    line-height: 1;
    border: 1px solid var(--fill-warm-gray);
    border-radius: $cornerRound;
    flex: 1 1 auto;
    min-height: 3.125rem;
    justify-content: center;
    &:first-child:not(:only-child) {
      margin-right: auto;
      align-items: flex-start;
    }
    &--full {
      flex-basis: 100%;;
    }
  }
  &__search {
    background-color: var(--fill-white);
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    line-height: 1;
    appearance: none;
    gap: 0.5rem;
    padding-right: .5rem;
    width: 100%;
    &-input {
      @include clean();
      height: 100%;
      padding: .5rem .5rem .5rem 1rem;
      width: inherit;
      cursor: auto;
      height: 100%;
    }
    &-submit {
      @include clean();
    }
  }
  &__label {
    font-size: 0.75rem;
    padding: 0 0.25rem;
    opacity: 0.5;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    &--large {
      // font-size: $h3Size;
      opacity: 1;
      letter-spacing: 0;
      text-transform: none;
    }
  }

  // Removed because setting this dynamically per user is causing CLS
  // &__view {
  //   display: flex;
  //   margin-right: auto;
  //   gap: 1rem;

  //   &-button {
  //     all: unset;
  //     appearance: none;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     line-height: 1;
  //     cursor: pointer;
  //     font-size: 1.5rem;
  //     padding: 0.25rem 0.25rem 0.125rem;
  //     background: $coolGray;
  //     transition: 0.2s ease;

  //     &:hover {
  //       background: var(--fill-warm-gray);
  //     }

  //     &[data-view="grid"] {
  //       &:is([data-view="grid"] *) {
  //         background: $linkColor;
  //         color: $white;
  //       }
  //     }

  //     &[data-view="list"] {
  //       &:is([data-view="list"] *) {
  //         background: $linkColor;
  //         color: $white;
  //       }
  //     }
  //   }
  // }

  &__select {
    display: flex;
    position: relative;
    min-width: 175px;

    &-active {
      all: unset;
      appearance: none;
      flex: 1 1 100%;
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      // border: 1px solid var(--fill-warm-gray);
      cursor: pointer;
      &::after {
        content: '';
        height: 0.3875rem;
        width: 0.3875rem;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        transform: translateY(10%) rotate(45deg);
        transition: 0.2s ease;
      }

      &[data-active] {
        &::after {
          transform: translateY(30%) rotate(-135deg);
        }
      }
    }

    &-menu {
      display: flex;
      flex-direction: column;
      background: $white;
      z-index: 10;
      position: absolute;
      top: calc(100% + 0.75rem);
      left: 0;
      right: 0;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      border: 1px solid var(--fill-warm-gray);
      overflow-y: auto;
      max-height: 200px;
    }

    &-item {
      display: flex;
      flex-direction: column;
    }

    &-link {
      &:where(button){
        appearance: none;
        background: none;
        border: none;
        cursor: pointer;
      }
      display: flex;
      justify-content: space-between;
      gap: 0.75rem;
      font-size: 0.875rem;
      color: $fontColorBase;
      transition: 0.2s ease;
      padding: 0.3875rem 0.75rem;

      &::after {
        content: '';
        height: 0.5rem;
        width: 0.25rem;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        transform: translateY(10%) rotate(45deg);
        opacity: 0;
        transition: 0.2s ease;
      }

      &:hover {
        background: $coolGray;
        text-decoration: none;
        &::after {
          opacity: 0.25;
        }
      }

      &--active,
      &--active:hover {
        color: $linkColor;
        font-weight: $fontWeightBold;
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    
.list-item {
  --gap: 0.25rem;
  --spacing: calc((var(--gap) * 4) + 1px);
  --adjust: calc(var(--spacing) * -1);

  display: flex;
  flex-direction: column;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
  border-radius: 8px;
  background-color: var(--fill-white);
  @each $name, $color in $fills {
    :where(.composition--fill-#{'' + $name}) & {
      color: var(--fill-black);
    }
  }
  &--article {
    .list-item {
      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    :is([data-view="list"]) & {
      .list-item__inner {
        @include mq(md) {
          display: grid; 
          grid-template-columns: 0.4fr 1.6fr; 
          grid-template-rows: max-content; 
          gap: 0.8125rem 1.5rem; 
          grid-template-areas: 
            "media ."
            "media ."
            "media ."
            "media ."; 
        }
      }
      .list-item__media {
        grid-area: media;
        @include mq(md) {
          align-items: baseline;
        }
        
      }
    }
  }
  &--large {

  }
  &--small {
    .list-item__inner {
      @include mq(md) {
        flex-direction: row;
      }
    }
    .list-item__media {
      @include mq(md) {
        flex: 1 0 50%;
        max-width: 103px;
        align-items: baseline;
      }
      
    }
  }
  &--resource {
    .list-item {
      &__date {
        font-size: 0.875rem;
        line-height: 1;
        margin-bottom: .5rem;
        color: var(--fill-ruby);
        &:after {
          margin: 0 0.5rem;
        }
      }
    }
  }
  &__inner,
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: calc(var(--gap) * 3.25);
    column-gap: calc(var(--gap) * 6);
    // color: $fontColorBase;
    
    transition: none;
  }
  &__inner {
    padding: 1.4375rem 1.25rem;
  }
  &__media {
    border-radius: 8px;
    display: flex;
    overflow: hidden;
  }

  &__image {
    border-radius: 8px;
    display: flex;
  }


  // &:is([data-view="list"] *) {
  //   &:not(:last-child) > * {
  //     padding-bottom: 1.5rem;
  //     margin-bottom: 1.5rem;
  //     border-bottom: 1px solid $coolGray;
  //   }
  // }

  &:is([data-view="grid"] *) {
    border: 1px solid $coolGray;
    padding: 1rem;
    border-radius: $cornerRound;
    .list-item__image {
      visibility: visible;
      //max-height: none;
      //max-width: none;
    }
  }
  &__date {
    font-size: 0.875rem;
    line-height: 1;
    margin-bottom: .5rem;
    color: var(--fill-ruby);
    font-weight: $fontWeightBold;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    overflow: hidden;
    gap: var(--gap) 0;
    text-transform: uppercase;
    font-weight: $fontWeightBold;
  }

  &__title {
    font-weight: $fontWeightBold;
    font-size: 1.5rem;
    margin: 0;
    transition: 0.2s ease;
  }
  
  &__link {
    text-decoration: none;
    color: $linkColor;
    
    &:hover {
      text-decoration: none;
      color: $linkColorHover;
    }
  }

  &__copy {
    font-size: 1rem;
    margin: 0;
    line-height: 1.5;
    > * {
      margin-bottom: calc(var(--gap)*3.25);
    }
  }

}
  
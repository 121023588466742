
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .footer {
  background-color: var(--fill-warm-gray);

  @include mq(xl) {
    background-image: linear-gradient(90deg, var(--fill-warm-gray) 0%, var(--fill-warm-gray) 59%, var(--fill-cool-gray) 60%);
  }

  &__inner {
    display: flex;
    flex-direction: column;

    @include mq(lg) {
      flex-direction: row;
    }

    @include mq(xl) {
      @include container;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: start;
    width:8rem;
    margin:0 auto;

    img {
      width:100%;
      max-width: 8rem;
      aspect-ratio: 1;
    }

    @include mq(md) {
      margin:0;
      position: absolute;
      top: -4px;
      left: 0;
      width: 20%;
    }

    @include mq(lg) {
      width: 7.5rem;
    }
  }

  &__main {
    padding: 3rem 0 2rem;

    @include mq(lg) {
      padding: 4rem 0 3rem;
      flex-basis: 50%;
    }

    @include mq(xl) {
      flex-basis: 44%;
    }

    &-inner {
      position: relative;
      @include container;

      @include mq(md) {
        padding-left: 20%;
      }

      @include mq(lg) {
        padding-left: 9.5rem;
      }
    }
  }

  &__extras {
    background-color: var(--fill-cool-gray);
    padding: 2rem 0;

    @include mq(lg) {
      padding: 4rem 0;
      flex-basis: 50%;
    }

    @include mq(xl) {
      flex-basis: 60%;
      padding-left: 3rem;
      padding-right:1rem;
    }

    &-inner {
      @include container;

      @include mq(md) {
        padding-left: 20%;
      }

      @include mq(lg) {
        padding-left: 0;
      }

      @include mq(xl) {
        padding-left: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 1rem;
        margin:0;
        width:100%;
      }
    }
  }

  &__nav-group-social {
    flex-shrink: 0;
  }

  &__nav-groups {
    @include mq(md) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 2rem;
      margin-bottom: 2rem;

      &>* {
        flex-basis: 50%;
      }
    }

    @include mq(xl) {
      flex-grow:1;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: var(--fill-black);
    cursor: default;
    font-weight: bold;
    margin-bottom: 1.5rem;

    &--slug {
      color: var(--fill-tan);
      margin-bottom: .5rem;
      text-transform: uppercase;
      font-family: $fontFamilyHeadings;

      span {
        text-transform: lowercase;
        font-style: italic;
      }

      @include mqdn(sm) {
        display: inline-block;    
        text-align:left;
        margin-top: 1rem;
      }

      @include mq(md) {
        min-width:360px;
      }
    }

    @include mqdn(md) {
      text-align: center;
      justify-content: center;
    }

    .footer__nav & {
      @include mqdn(md) {
        gap: 0.5rem;
        cursor: pointer;
      }
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    font-style: normal;
    margin: 0;

    @include mq(xl) {
      padding-right: 3rem;
    }

    .footer__list {
      margin-top: 2rem;
      gap: 0;
    }

    .footer__item {
      --addressPadding: .65rem;
      border-top: 1px solid rgba(0, 0, 0, .15);
      padding: var(--addressPadding) 0 var(--addressPadding);

      @include mq(xl) {
        padding: 1rem 0;
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    // gap: 0.5rem;
    &--social {
      .footer__item {
        font-weight: bold;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 1rem;
    list-style: none;

    @include mqdn(md) {
      &>*:first-child {
        padding-top: 0.5rem;
      }

      &>*:last-child {
        padding-bottom: 1rem;
      }
    }

    @include mq(md) {
      align-items: stretch;

    }
  }

  &__item {
    font-size: 1rem;

    address & {
      @include mq(sm) {
        display: inline-block;
        vertical-align: middle;
      }
    }

    &-label {
      padding-right:.15rem;
      flex-shrink:1;
      display:inline-block;
      line-height:1.1;
    }

    &-value {
      flex-shrink: 0;
    }
  }

  &__link {
    color: var(--fill-blue);
    cursor: pointer;
    flex: 0 0 auto;
    transition: 0.2s ease;

    &:hover {
      text-decoration: none;
    }
  }

  &__contact {
    gap: 2rem;
    position: relative;

    @include mqdn(md) {
      // &::before {
      //   position: absolute;
      //   content: '';
      //   top: 0;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   width: 100vw;
      //   height: 1px;
      //   background: $warmGray;
      // }
    }

    @include mq(md) {
      flex: 0 0 auto;
      min-width: 30%;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    gap: 1rem 0;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;

    @include mq(md) {
      gap: 1rem 0.5rem;
      margin: 0;
    }

    .footer__title {
      flex: 0 0 100%;
      grid-area: 1 / 1 / 2 / 3;
    }
  }

  &__email {
    border: none;
    padding: 0.75rem;
    font-size: 0.875rem;
    grid-area: 2 / 1 / 3 / 2;
    width: auto;
    max-width: 100%;
    min-width: 0;
  }

  &__submit {
    grid-area: 2 / 2 / 3 / 3;
  }

  &__social {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
    gap: 0 1.5rem;
    justify-content: center;

    a {
      font-size: 1.5rem;
      color: var(--fill-gray);

      &:hover {
        color: var(--fill-ruby);
      }
    }

    @include mq(md) {
      justify-content: start;
      grid-template-columns: auto auto auto auto 1fr;
    }

    .footer__title {
      grid-area: 1 / 1 / 2 / 5;

      @include mq(md) {
        grid-area: 1 / 1 / 2 / 6;
      }
    }

    .footer__link {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      grid-row: 2 / 3;
      grid-column: span 1;
    }
  }

  &__meta {
    background: #fff;

    &-inner {
      @include container;
      display: flex;
      flex-direction: column;
      background-color: var(--fill-white);
      align-items: center;
      padding: 1rem 0;
      gap: 0.5rem 2rem;
      text-align: center;

      @include mq(md) {
        text-align: left;
        flex-direction: row;
      }
    }
  }

  &__copy {
    grid-column: 1 / 4;

    @include mq(md) {
      margin-right: auto;
    }
  }

  &__legal {
    display: flex;
    justify-content: center;
    gap: 2rem;

    @include mq(md) {
      justify-content: end;
    }

    a {
      &:hover {
        color: $blue;
      }
    }
  }
}
  
*:where([component]:not(:defined)) {
  visibility: hidden;
  display: contents;
  box-sizing: border-box;
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

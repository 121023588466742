
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .breadcrumbs {
  --gap: 0.75rem;
  --spacing: calc((var(--gap) * 2) + 1px);
  --adjust: calc(var(--spacing) * -1);
  display: flex;
  margin: var(--spacing) 0;
  color: var(--fill-white);
  background-color: var(--fill-gray);
  padding: 0;
  margin: 0 calc(-50vw + 50%);
  max-width: none;
  width: 100vw;
  &__inner {
    display: flex;
    max-width: $maxWidth;
    align-items: center;
    padding: 0 var(--gap);
    @include mq(xxl) {
      padding: 0;
    }
    @include mq(lg) {
      justify-content: space-between;
      margin: auto;
      width: 100%;
    }
  }
  &__nav {
    display: flex;
    overflow: hidden;
    gap: 0.75rem 0;
    max-height: 44px;
    
  }

  &__nav-item {
    display: flex;
    align-items: center;
    color: currentColor;
    margin-right: var(--spacing);
    font-size: 1rem;
    line-height: 3;
    margin-right: var(--spacing);
    white-space: nowrap;
    &:where(a) {
      &:hover {
        text-decoration: none;
        &::before {
          // color: initial;
        }
      }
    }
    &::before {
      display: flex;
      justify-content: center;
      width: var(--spacing);
      margin-left: var(--adjust);
      font-size: 1rem;
      content: '|';
      pointer-events: none;
      color: var(--fill-white);
      opacity: .33;
    }
    &:last-child {
      // color: $gray;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    gap: var(--gap);
  }

  &__social-item {
    font-size: 1.325rem;
    color: inherit;
    line-height: 0;
    &:hover {
      text-decoration: none;
      color: var(--fill-light-blue);
    }
  }
}

  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .cookie-notice {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  z-index: 100;
  gap: 2rem;
  animation: slideInFromBelow 750ms ease;
  &.hidden {
    pointer-events: none;
    transform: translateY(100%);
    transition: 0.2s ease; 
  }  
  &__modal {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background: var(--fill-blue);
    border-top: 4px solid var(--fill-ruby);
    z-index: 100;
    color: var(--fill-white);
    bottom: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    overflow: hidden;
    &-header {
      @include container;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 1.5rem;
      margin-bottom: 0;
      em {
        font-style: normal;
      }
    }
    &-title {
      @include fluid(font-size, 1.25rem, 1.5rem);
      margin: 0;
    }
    &-content {
      @include container;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 1.5rem;
      @include mq(sm) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    &-copy {
      font-size: 0.875rem;
      margin: 0;
      flex: 0 1 75%;
      a {
        color: var(--fill-white);
        text-decoration: underline;
        &:hover {
          color: var(--fill-white);
        } 
      }
    }
    &-responses {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: auto;
    }
    &-btn[class][class] {
      flex: 1 1 0;
      padding: 0.75rem 1.25rem;
      font-size: 0.75rem;
      &.btn--ghost {
        border-color: var(--fill-white);
        color: var(--fill-white);
        &:hover{
          color: var(--fill-blue);
        }
      }
    }
    &-close {
      background: transparent;
      border: none;
      position: relative;
      height: 20px;
      width: 20px;
      padding: 0;
      cursor: pointer;
      color: inherit;
      &::after,
      &::before {
        content: '';
        position: absolute;
        height: 4px;
        width: 18px;
        border-radius: 1px;
        background: currentColor;
        left: 1px;
        top: calc(50% - 1px);
      }
      &::after {
        transform: rotate(45deg);
      }
      &::before {
        transform: rotate(-45deg);
      }
    }
  }
}

  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .pagination {
  display: flex;
  align-items: center;
  align-self: center;
  gap: 0.3875rem;

  &__prev,
  &__next,
  &__number {
    @include fluid(height, 1.5rem, 2rem);
    @include fluid(font-size, 0.875rem, 1rem);
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    line-height: 1;

    &:where(a) {
      color: $black;
      &:hover {
        color: $white;
        background-color: $warmGray;
        text-decoration: none;
      }
    }
  }

  &__prev,
  &__next {
    &::before {
      content: '';
      height: 0.5em;
      width: 0.5em;
      border-top: 2px solid currentColor;
      border-left: 2px solid currentColor;
      transform: translateY(-10%) translateX(-25%) rotate(135deg);
    }
    span {
      @include sr-only;
    }
  }

  &__prev {
    &::before {
      transform: translateY(-10%) translateX(25%) rotate(-45deg);
    }
  }

  &__ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    &::after {
      content: '';
      height: 0.125rem;
      width: 0.125rem;
      background: currentColor;
      border-radius: 50%;
      box-shadow: -0.25rem 0 0 currentColor, 0.25rem 0 0 currentColor;
    }
  }

  &__number-container {
    display: flex;
    align-items: center;
    gap: 0.3875rem;
  }

  &__number {
    &--active {
      color: $white;
      background: $linkColor;
    }
  }
}

  
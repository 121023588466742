/*******************************************
******* GLOBAL OVERRIDES *******************
**** You can reassign these global *********
**** defaults by un-commenting the *********
**** variables below & giving them *********
**** new values. The comments show *********
**** the default values.           *********
*******************************************/
// $rootEM: 16px !default;

// $success: #5ac18e !default;
// $warning: #ffd700 !default;
// $error: #ff6666 !default;

// $minWidth: 20rem !default;
$maxWidth: 85.375rem !default;
// $maxWidthSm: 64rem !default;
// $gutter: 2rem !default;
// $gutterSm: math.div($gutter, 2) !default;
// $gutterXs: math.div($gutter, 4) !default;
// $gutterLg: $gutter * 1.5 !default;
// $gutterXl: $gutter * 2 !default;
// $marginBase: 1.5rem !default;

// $gridColumns: 12 !default;
// $gridBreakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
// ) !default;

// $gridGaps: (
//   '0': 0,
//   '05': $gutterXs,
//   '1': $gutterSm,
//   '2': $gutter,
//   '3': $gutterLg,
//   '4': $gutterXl,
// ) !default;

// $breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   sm_dn: 575px,
//   md_dn: 767px,
//   lg_dn: 991px,
//   xl_dn: 1199px,
// ) !default;

// // bodyInteractive: in-article elements like sliders, accordions, tabs
// // bodyAlert: in-article style alert/warning element
// // modalAlert: pop up alert like a cookie/gdpr warning
// // scrim/modal: typical modals, separate from modalAlert due to potential marketing value

// $z-layers: (
//   base: 1,
//   button: 4,
//   articleInteractive: 6,
//   breadcrumbs: 9,
//   bodyAlert: 10,
//   header: 12,
//   tooltip: 14,
//   modalAlert: 15,
//   scrim: 17,
//   modals: 18,
//   override: 19,
//   max: 20,
// ) !default;

/******************************************/

/*******************************************
******* BRAND/GLOBAL COLORS ****************
*******************************************/
$white: #fff;
$black: #000;
$gray: #53565A;
$warmGray: #EDE9E5;
$coolGray: #F4F4F4;
$blue: #004C97;
$lightBlue: lighten($blue, 68%);
$ruby: #9B2743;
$tan: #716258;
// These colors are found in the sketch document here: https://bluehq.sharepoint.com/:f:/s/Clients/Es9ZsTVifD1Es7rJ7gzzhwQBzvrd3PHNb7TBd2SGqnvolQ?e=mKByPK
// and may not be used
$brandGreen: #789D4A;
$brandPurple: #6D2077;
$brandOrange: #FF8200;
$brandGray: #6E6259;
$brandOrange: #94450B;
$brandBlue: #002855;
$brandYellow: #F2C75C;
// Form colors
$formInputBorder: #3D505A;
// Gradients
$blueStart: #003366;
$blueEnd: #004C97;
/******************************************/
$fontColorBase: $black;
$fontColorLight: $coolGray;

$linkColor: var(--fill-blue);
$linkColorHover: darken($blue, 25%);

$buttonPrimaryBackground: var(--fill-blue);
$buttonPrimaryBackgroundHover: #004489;
$buttonPrimaryColor: $white;
$buttonPrimaryColorHover: $white;

$buttonSecondaryBackground: $ruby;
$buttonSecondaryBackgroundHover: #8b233c;
$buttonSecondaryColor: $white;
$buttonSecondaryColorHover: $white;

$tableHighlight: $coolGray;

$focusProperty: outline;
$focusValue: 0.0875rem solid $brandYellow;
/******************************************/
$cornerRound: 0.3125rem;

/*******************************************
******* COMPOSITION BACKGROUNDS ************
**** Any colors included in this   *********
**** map will have composition     *********
**** fill classes automatically    *********
**** generated.                    *********
**** EX. .composition--fill-blue   *********
**** EX. .u-background--ruby       *********
*******************************************/
$fills: (
  'blue': $blue,
  'light-blue': $lightBlue,
  'ruby': $ruby,
  'tan': $tan,
  'gray': $gray,
  'warm-gray': $warmGray,
  'cool-gray': $coolGray,
  'white': $white,
  'black': $black,
);
/******************************************/

/*******************************************
******* TYPOGRAPHY *************************
*******************************************/
$fontSizeBase: 1rem;
$lineHeightBase: 1.5;
$fontWeightLight: 300;
$fontWeightBase: 400;
$fontWeightBold: 700;
$fontFamilyBase: 'Nunito Sans', sans-serif;

$heroSize: clamp(1.75rem, 4vw, 3.875rem);
$heroSizeLineHeight: 1.2;

$h1Size: 3rem;
$h1SizeMobile: 2rem;
$h1SizeLineHeight: calc(3.5rem / $h1Size);

$h2Size: 2.25rem;
$h2SizeMobile: 1.625rem;
$h2SizeLineHeight: calc(2.625rem / $h2Size);

$h3Size: 1.75rem;
$h3SizeMobile: 1.375rem;
$h3SizeLineHeight: calc(2.25rem / $h3Size);

$h4Size: 1.75rem;
$h4SizeMobile: 1.375rem;
$h4SizeLineHeight: calc(1.875rem / $h4Size);

$h5Size: 1.25rem;
$h5SizeMobile: $h5Size;
$h5SizeLineHeight: calc(1.75rem / $h5Size);

$h6Size: 1.25rem;
$h6SizeMobile: $h6Size;
$h6SizeLineHeight: 1.25;

$liSize: 1.125rem;

$lineHeightHeadings: 28px;

$fontWeightHeadings: 700;
$fontFamilyHeadings: 'Libre Baskerville', serif;
/******************************************/

/* Spacing */
$paddingSm: 0.25rem;
$paddingMd: 0.5rem;
$paddingLg: 1.25rem;
$paddingXl: 2rem;

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .county-map {
    display: block;
    .list-tools {
        background-color: none;
        padding: 0;
        margin-bottom: 1rem;
    }
    &--reverse {
        .county-map__inner {
            flex-direction: row-reverse;
        }
        .county-map__svg {
            width: 75%;
        }
    }
    &--small {
        .county-map__inner {
            align-items: center;
            padding-bottom: 1rem;
            padding-top: 1rem;
            background-color: unset;
        }
        .county-map__column {
            @include mq(lg) {
                
                &:first-of-type {
                    max-width: 903px;
                    flex: 1 1 70%;
                    width: 70%;
                }
                &:last-of-type {
                    max-width: 324px;
                }   
            }
        }
    }
    &__inner {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: var(--fill-cool-gray);
        border-radius: 15px;
        padding: 1rem;
        @include mq(lg) {
            flex-direction: row;
            column-gap: 52px;
            padding: 75px 70px 75px 55px;
        }
    }
    &__column {
        @include mq(lg) {
            flex: 1 1 50%;
            width: 50%;
            &:first-of-type {
                
                max-width: 477px;
            }
            &:last-of-type {
                max-width: 713px;
            }   
        }
    }
    &__title {
        font-family: $fontFamilyBase;
        border-top: 1px solid #E0E0E0;
        padding-top: 3.5rem;
    }
    &__info {
        display: flex;
        gap: .5rem;
        margin-bottom: .25rem;
    }
    &__cta {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: start;
        margin-top: 1.86rem;
    }
    &__svg {
        display: inline-flex;
        width: 100%;
        height: auto;
        path {
            fill: #bcb8b7;
            &.is-active {
                fill: var(--fill-ruby);
            }
        }
    }
}
// Selector for the county razor view section
.county {
    .grant-list thead th {
        &:first-of-type,
        &:last-of-type {
            width: auto;
        }
    }
    &--is-inactive {
        // default state of page, toggled off by js-tab-toggle buttons contained in county-map 
        .county__content,
        .tabs__nav,
        .county-map__description,
        .county-map__title + p {
            display: none;
        }
    }
    &--is-active {
        h3:not(.county-map__title) + p {
            display: none;
        }
        .county-map__title + p {
            display: block;
        }
        .county-map--reverse {
            h3:not(.county-map__title) {
                display: none;
            }
            .county-map__title {
                border-top: none;
                padding-top: 0;
            }
            .list-tools {
                display: none;
            }
            .county-map__cta {
                a {
                    display: none;
                    &:last-of-type {
                        display: inline-flex;
                    }
                }
            }
        }
        .tabs__tabpanel--overview {
            .county-map__inner {
                h3,.list-tools,.county-map__cta,.county-map__intro {
                    display: none;
                }
            }
        }
    }
}
  
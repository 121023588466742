
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .media-object {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: $paddingMd;
    @include mqdn(sm) {
        flex-direction: column;
    }
    // &--vertical {
    //     align-items: center;
    //     flex-direction: column;
    // }
    &--reverse {
        flex-direction: row-reverse;
        @include mqdn(sm) {
            flex-direction: column-reverse;
        }
    }
    &--center {
        align-items: center;
    }
    &__media {
        align-items: center;
        display: flex;
        // &--sm {
        //     max-width: 3.5rem;
        // }
    }
    &__content {
        flex: 1;
        * {
            margin: 0;
        }
    }
}
  
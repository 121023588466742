
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .person-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &--horizontal {
        @include mq(md) {
            flex-direction: row;
            align-items: center;
            gap: 2rem;
        }
        .person-list__media {
            max-width: none;
        }
    }
    &--alt {
        @include mq(sm) {
            flex-direction: row;
            gap: 2rem;
            align-items: center;
        }
        
    }
    &__media {
        margin: 0;
        width: 100%;
        &[data-a11y-dialog-show] {
            cursor: pointer;
        }
        max-width: 20.3125rem;
    }
    &__image {
        display: flex;
        height: 100%;
        width: 100%;
        max-width: 20.3125rem;
        overflow: hidden;
        max-height: 20.3125rem;
        img {
            width: inherit;
        }
    }
    &__content {
        font-size: 1.125rem;
    }
    &__name {
        color: var(--fill-blue);
        font-size: 1.4375rem;
        margin-bottom: .25rem;
        font-weight: 600;
    }
    &__title {
        margin-bottom: 1rem;
    }
    &__copy {
        border-top: 1px solid var(--fill-warm-gray);
        border-bottom: 1px solid var(--fill-warm-gray);
        padding: 1rem 0;
        margin: 0 auto 1rem;
    }
    &__links {
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 0px 0px; 
    }
    &__link {
        &:nth-child(1) {
            grid-row: 1;
            grid-column: 1;
        }
        &:nth-child(2) {
            grid-row: 2;
            grid-column: 1;
        }
        &:nth-child(3) {
            grid-row: 1 / 3;
            grid-column: 3;
            align-self: center;
        }
        &--linkedin {
            $size: 38px;
            color: var(--fill-white);
            background-color: var(--fill-blue);
            display: flex;
            align-items: center;
            justify-content: center;
            height: $size;
            width: $size;
            border-radius: 5px;
            i {
                font-size: 1.5rem;
                line-height: 0;
            }
        }
    }
}
  
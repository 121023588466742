//for the global page wrapping div
.page {
    // check for proper grid support
    @supports (display: grid) {
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: minmax(0, 1fr);
        min-height: 100vh;
    }
    // this is used to ensure the star on app-link-list doesnt overflow the page
    overflow: hidden;
}

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .header {
  &__tools {
    display: flex;
    gap: 0.5rem;
    align-self: center;
    justify-content: flex-end;
    grid-area: nav-tools;
    height: 2.625rem;
    position: relative;
    @include mqdn(md) {
      flex-basis: 100%;
      height: auto;
    }
  }

  &__search {
    flex: 1 1 100%;
    justify-content: end;
    opacity: 0;
    max-width: 1px;
    background: var(--fill-warm-gray);
      display: flex;
      flex-direction: row-reverse;
      margin: auto;
    transition: max-width 500ms ease-in-out, 300ms opacity 250ms ease-in-out;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    
    @include mqdn(md) {
      max-width: 768px;
      opacity: 1;
      width: 100%;
    }
    &[aria-hidden='true'] {
      display: none;
    }
    .header__tools.is-active & {
      @include mq(md) {
        max-width: 350px;
      }
      opacity: 1;
      transition: max-width 500ms ease-in-out, opacity 250ms ease-in-out;
      input {
        display: flex;
      }
    }
    @include mq(lg) {
      width: 100vw;
    }

    &-input {
      appearance: none;
      border: none;
      background: transparent;
      border-radius: 0;
      padding: 0.3875rem 0 0.3875rem 0.75rem;
      flex: 1 1 100%;
      font-size: 1rem;
      width: auto;
      min-width: 0;
      &::placeholder {
        font-size: 0.875rem;
      }

      @include mq(md) {
        padding: 0.625rem 0 0.625rem 1rem;
      }
    }

    &-submit,
    &-toggle {
      appearance: none;
      border: none;
      background: transparent;
      border-radius: 0;
      padding: .25rem 0.5rem 0;
      font-size: 1.25rem;
      color: $fontColorBase;

      @include mq(md) {
        font-size: 1.5rem;
      }
    }
    &-submit {
      @include mqdn(md) {
        @include sr-only;
      }
    }
    &-toggle{
      background-color: var(--fill-white);
      z-index: 10;
      @include mqdn(md) {
        background-color: var(--fill-warm-gray);
        padding: 1rem;
        max-width: 4.5rem;
        margin-left: 0.25rem;
        width: 100%;
        font-size: 1.25rem;
      }
    }
  }

}
  
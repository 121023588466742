
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .grant-list {
    display: block;
    margin-top: 1.5rem;
    &.is-filtered {
        tbody {
            tr{
                display: none;
                &.is-active {
                    display: table-row;
                }
            }
        }
    }
    table {
        border-collapse: collapse;  
        table-layout: fixed;
        width: 100%;
        color: var(--fill-black);
        
    }
    // caption {
    //     font-size: $h3Size;
    //     text-align: left;
    // }
    thead {
        
        text-align: left;
        tr{
            border-bottom: 3px solid var(--fill-warm-gray);
        }
        th {
            font-weight: 600;
            button {
                $caretWidth: 0.5625rem;
                $caretHeight: calc($caretWidth * .5);
                @include clean();
                position: relative;
                padding-right: $caretWidth * 2;
                text-align: left;
                span {
                    display: block;
                    width: 100%;
                    // before is top arrow and after is bottom arrow
                    &:after,
                    &:before {
                        content:'';
                        height: $caretHeight;
                        width: $caretWidth;
                        background-image: url('img/template-images/caret.svg');
                        position: absolute;
                        right: .85rem;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        transform: translateY(calc(($caretHeight * .75) * -1));
                        opacity: .5;
                        @include mq(sm) {
                            right: 0;
                        }
                    }
                    &:after {
                        transform: rotate(180deg) translateY(calc($caretHeight * -1));
                    }
                }
            }
            &[aria-sort="descending"] {
                button span {
                    &:after {
                        opacity: 1;
                    }
                }
            }
            &[aria-sort="ascending"] {
                button span {
                    &:before {
                        opacity: 1;
                    }
                }
            }
            &:hover:not([aria-sort="descending"]):not([aria-sort="ascending"]) {
                button span {
                    &:before,
                    &:after {
                        opacity: .75;
                    }
                }
            }
            &:first-of-type {
                @include mq(sm) {
                    width: 55%;
                }
            }
            &:last-of-type {
                @include mq(sm) {
                    width: 15%;
                }
            }
        }
        
    }
    tbody{
        tr {
            border-bottom: 1px solid var(--fill-warm-gray);
            td {
                .glyph-clock {
                    color: var(--fill-ruby);
                    margin-left: .85rem;
                    vertical-align: middle;
                }
            }
        }
    }
    tfoot {
        tr {
        }
    }
    th { 
        padding-top: $paddingMd;
        padding-bottom: $paddingMd;
    }
    td {
        padding-top: $paddingLg;
        padding-bottom: $paddingLg;
    }
}
   
  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .article-card {
  // used for calculating the transform
  --mediaSize: 6.4375rem;
  $innerPad: 1.5rem;
  color: var(--fill-black);
  @include mqdn(lg) {
    --mediaSize: 5rem;
  }
  :where(.grid--match-height) &{
    display: flex;
    .article-card__inner {
      height: auto;
      align-self: stretch;
      flex: 1 1 100%;
    }
  }
  // This might be unnecessary, since a lot of styles have disappeared
  &:where(:not(.article-card--tall)) {
    .article-card__media {
      max-height: var(--mediaSize);
    }
  }
  &--tall {
    .article-card__media {
        max-width: 24.375rem;
        margin-left: auto;
        margin-right: auto;
        img {
          width: 100%;
          height: 100%;
        }
    }
    // not in design, but may be needed later
    // .article-card__category {
    //   margin-top: .25rem;
    //   text-align: right;
    // }
    .article-card__title {
      margin-bottom: 1rem;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.625rem;
    padding: $innerPad $innerPad $innerPad * 1.5;
    background: var(--fill-white);
    
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    @include mq(sm) {
      flex-direction: row;
    }
  }
  &__media {
    flex: 1 0 auto;
    max-width: var(--mediaSize);
    border-radius: $cornerRound;
    overflow: hidden;
    width: 100%;
    // media size plus padding * 1.5 divided by 2 inverse
    margin-top: calc(((var(--mediaSize) + ($innerPad * 1.5)) *.5) * -1);
    background-color: var(--fill-white);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  }
  &__image,
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // block covers picture element padding issues
    display: block;
    margin: auto;
  }
  &__category {
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    
    
    @include mq(sm) {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }
    @include mqdn(sm) {
      margin-top: .5rem;
    }
  }
  &__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding-top: 1.5rem;
    font-weight: bold;
    @include mq(md) {
      font-size: clamp(1rem, 1.5vw, 1.5rem);
    }
  }
  &__cta {
    color: $linkColor;
    font-weight: bold;
    &:after{
      content:'\2192';
      margin-left: 0.5rem;
    }
  }
}
  
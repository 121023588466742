section {
  width: 100%;
  margin-bottom: $gutterLg;
  .section__inner {
    position: relative;
  }
  &.section--sm {
    & > .section__inner {
      @include container($max: $maxWidthSm);
    }
  }
  &:not(.section--sm) {
    & > .section__inner {
      @include container;
    }
  }
  // Left rail
  &.section--rail-left {
    $gutterLeft: 1.3125rem;
    & > .section__inner {
      @include mq(lg) {
        display: flex;
        
        & > .section__main,
        & > .section__rail {
          overflow: hidden;
          margin: 0;
          &:nth-child(1) {
            margin-left: 0;
          }
          &:nth-child(2) {
            margin-right: 0;
          }
          &:only-child {
            margin: 0;
          }
        }
        & > .section__main {
          flex: 1 1 calc(100% - #{math.div($gutterLeft, 2)});
          margin-left: $gutterLeft;
          max-width: 56.5rem;
           > *:not(:last-child) {
            display: block;
            margin-bottom: $gutterLg;
          }
        }

        & > .section__rail {
          flex: 0 0 calc(30rem - #{math.div($gutterLeft, 2)});
          max-width: 27.5625rem;
        }
      }
    }
  }
  // Right rail
  &.section--split {
    $gutterRight: 1.3125rem;
    & > .section__inner {
      @include mq(lg) {
        display: flex;
        
        & > .section__main,
        & > .section__rail {
          overflow: hidden;
          margin: 0;
          &:nth-child(1) {
            margin-left: 0;
          }
          &:nth-child(2) {
            margin-right: 0;
          }
          &:only-child {
            margin: 0;
          }
        }
        & > .section__main {
          flex: 1 1 calc(100% - #{math.div($gutterRight, 2)});
          margin-right: $gutterRight;
          max-width: 56.5rem;
           > *:not(:last-child) {
            display: block;
            margin-bottom: $gutterLg;
          }
        }

        & > .section__rail {
          flex: 0 0 calc(30rem - #{math.div($gutterRight, 2)});
          max-width: 27.5625rem;
        }
      }
    }
  }
}

/*
Description: Helper classes editorial content.
*/
.u-text {
    /* Position */
    &--left {
        text-align: left;
    }
    &--center {
        text-align: center;
    }
    &--right {
        text-align: right;
    }
    /* */
    &--uppercase {
        text-transform: uppercase;
    }
    &--capitalize {
        text-transform: capitalize;
    }
    &--arrow {
        &:after {
            @include icon;
            content: "\e907";
            font-size: .8rem;
            padding-left: .5rem;
        }
    }
    &--strike {
        @include mq(md) {
            position: relative;
            width: 100%;
            overflow: hidden;
            &:after{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: var(--fill-warm-gray);
                bottom: 0;
                top: 0;
                margin: auto 0 auto .5rem;
                padding-left: .25rem;
            }
        }
    }
    /* Color */
    &--white {
        color: var(--fill-white);
        &:hover {
            color: inherit;
        }
    }
    &--black {
        color: var(--fill-black);
        &:hover {
            color: inherit;
        }
    }
}
// Generates utility classes for backgrounds
// See: variables.scss
// use: .u-background--fill-#color
@each $name,
$color in $fills {
    .u-background--#{'fill-' + $name} {
        background-color: var(--fill-#{$name});
        background-image: none;
    }
}
// This is an override to accommodate client, SD, SL, and BE developer needs.
// Ideally tan is removed from the CMS and replaced with the appropriate warm gray
.u-background--fill-tan {
    background-color: var(--fill-warm-gray) !important;
    *:not(.btn) {
        color: var(--fill-black) !important;
    }
}
// Generates utility classes for margin
// use: .u-margin-$breakpoint-#, .u-margin--top-$breakpoint-#, .u-margin--bottom-$breakpoint-#, .u-margin--left-$breakpoint-#, .u-margin--right-$breakpoint-#
@each $name,
$width in $gridBreakpoints {
    @media (min-width: $width) {
        @for $j from 1 through 5 {
            .u-margin-#{$name}#{$j} {
                margin: #{$j}rem;
            }
            .u-margin--top-#{$name}#{$j} {
                margin-top: #{$j}rem;
            }
            .u-margin--bottom-#{$name}#{$j} {
                margin-bottom: #{$j}rem;
            }
            .u-margin--left-#{$name}#{$j} {
                margin-left: #{$j}rem;
            }
            .u-margin--right-#{$name}#{$j} {
                margin-right: #{$j}rem;
            }
        }
    }
}
// Generates utility classes for padding
// use: .u-padding-$breakpoint-#, .u-padding--top-$breakpoint-#, .u-padding--bottom-$breakpoint-#, .u-padding--left-$breakpoint-#, .u-padding--right-$breakpoint-#
@each $name,
$width in $gridBreakpoints {
    @media (min-width: $width) {
        @for $j from 1 through 5 {
            .u-padding-#{$name}#{$j} {
                padding: #{$j}rem;
            }
            .u-padding--top-#{$name}#{$j} {
                padding-top: #{$j}rem;
            }
            .u-padding--bottom-#{$name}#{$j} {
                padding-bottom: #{$j}rem;
            }
            .u-padding--left-#{$name}#{$j} {
                padding-left: #{$j}rem;
            }
            .u-padding--right-#{$name}#{$j} {
                padding-right: #{$j}rem;
            }
        }
    }
}
// Generates utility classes for image control
// use: .u-max--width-$breakpoint, .u-max--height-$breakpoint
.u-max--width {
    max-width: calc(var(--width)*1px);
}
.u-max--height {
    max-height: calc(var(--height)*1px);
}
@each $name,
$width in $gridBreakpoints {
    @media (min-width: $width) {
        .u-max--width-#{$name} {
            max-width: calc(var(--width)*1px);
        }
        .u-max--height-#{$name} {
            max-height: calc(var(--height)*1px)
        }
    }
}

// Generates utility classes for image control
// use: .u-max--width-$breakpoint, .u-max--height-$breakpoint
.u-max--width {
    max-width: calc(var(--width)*1px);
    :where(.grid__item) & {
        width: 100%;
    }
}
.u-max--height {
    max-height: calc(var(--height)*1px);
}
@each $name,
$width in $gridBreakpoints {
    @media (min-width: $width) {
        .u-max--width-#{$name} {
            max-width: calc(var(--width)*1px);
        }
        .u-max--height-#{$name} {
            max-height: calc(var(--height)*1px)
        }
    }
}

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .on-page-nav {
    &__nav {   
        background-color: var(--fill-cool-gray);
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--fill-warm-gray);
        height: max-content;
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);
        max-width: none;
        width: 100vw;
        height: auto;
        position: static;
    }
    &__inner {
        // border-bottom: 1px solid var(--fill-cool-gray);
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: center;
        text-transform: uppercase;
        max-width: $maxWidth;
        align-items: center;

        @include mqdn(tabMobile) {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            justify-content: start;
            max-width: none;
        }

        .on-page-nav--events & {
            @include mqdn(tabMobile) {
                overflow:visible;
                -webkit-overflow-scrolling: auto;
                -ms-overflow-style: auto;
                min-height: 96px;
                position: relative;
            }
        }
    }
    &__next,
    &__prev {
        @include mq(tabMobile) {
            display:none;
        }
        cursor:pointer;
        padding:.25rem;
        display:block;
        position: absolute;
        top:50%;
        transform:translateY(-50%);
        border:0;
        color: $blue;
        background:none;

        &:focus {
            color: $ruby;
        }
    }
    &__next {
        right:1rem;
    }
    &__prev {
        left:1rem;
    }
    &__link {
        color: var(--fill-black);
        padding: 1.5rem clamp(2rem, 2vw, 4rem);
        position: relative;
        text-transform: uppercase;
        border-right: 1px solid var(--fill-warm-gray);
        text-align: center;
        white-space:nowrap;

        &:nth-of-type(1) {
            border-left: 1px solid var(--fill-warm-gray);
        }
        &:hover,
        &:focus,
        &.is-active{
            border-right: 1px solid var(--fill-warm-gray);
            font-weight: 600;
            text-decoration: none;
            outline: none;
            &:after{
                width: 100%;
                left: 0;
                right: 0;
            }
        }
        &:after{ 
            content: '';
            position: absolute;
            width: 0;
            height: 4px;
            bottom: 0;
            left: 50%;
            right: 50%;
            background: var(--fill-ruby);
            -webkit-transition: .2s ease;
            transition: .2s ease;
        }

        @include mqdn(tabMobile) {
            min-width: 13.75rem;
        }

        .on-page-nav--events & {
            @include mqdn(tabMobile) {
                position: absolute;
                top:50%;
                left:50%;
                transform:translate3d(-50%, -50%, 0);
                opacity:0;
                pointer-events:none;
                border:0;
                font-weight:bold;
                height:96px;
                line-height:96px;
                padding:0 1rem;

                &::after {
                    display:none;
                }

                &.is-active {
                    opacity:1;
                    pointer-events:auto;
                    border:0;
                }

                &.is-out-left {
                    transform:translate3d(-100%, -50%, 0);
                }

                &.is-out-right {
                    transform:translate3d(100%, -50%, 0);
                }
            }
        }
    }
}
  

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $marginBase;
  border: 1px solid $tableHighlight;
  border-collapse: collapse;

  &--striped {
    tbody tr:nth-child(odd) {
      background-color: $tableHighlight;
    }
  }

  &--bordered {

    td,
    th {
      border: 1px solid $tableHighlight;
    }
  }

  th,
  td {
    padding: 1rem;
    vertical-align: top;
    border-top: 1px solid $tableHighlight;
    text-align: inherit;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid $tableHighlight;
  }

  tbody+tbody {
    border-top: 2px solid $tableHighlight;
  }
}
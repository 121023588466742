
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    .alert {
  color: var(--fill-white);
  background: $blue;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  max-width: none;
  width: 100vw;
  height:auto;
  padding-right: 0.875rem;
  padding-left: 0.875rem;
  animation:slideInFromAbove 500ms ease;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  transition: grid-template-rows 0.2s ease;
  position: relative;
  &.hidden {
    pointer-events: none;
    grid-template-rows: 0fr;
  }  
  &--major {
    background-color: var(--fill-ruby);
  }
  &--interior {
    margin-top: .25rem;
  }
  &--static {
    border-top: 1px solid #00000025;
    border-bottom: 1px solid #00000025;
    background-color: var(--fill-white);
    color: var(--fill-black);
    .alert__icon,
    .alert__cta {
      fill: var(--fill-black);
    }
    .alert__close {
      color: var(--fill-black);
    }
  }
  &--minor {
    background-color: var(--fill-cool-gray);
    color: var(--fill-tan);
    .alert__icon,
    .alert__cta {
      fill: var(--fill-ruby);
    }
    .alert__close {
      color: var(--fill-black);
    }
  }
  &__track {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    max-width: $maxWidth;
    margin: auto;
  }

  &__inner {
    display: grid;
    grid-template-columns:
      minmax(0, 1fr) minmax(min-content, max-content)
      minmax(0, 1fr);
    gap: 1rem;
    padding: 0.85rem 1.5rem 0.85rem 0.85rem;
    flex: 1 1 100%;
  }

  &__content {
    grid-area: 1 / 2 / 2 / 3;
    gap: 1rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    & * {
      color: inherit;
      margin: 0;

      &:where(a) {
        text-decoration: underline;
      }
    }
    @include mqdn(sm) {
      display: block;
    }
  }
  &__icon {
    fill: var(--fill-white);
    @include mqdn(sm) {
      display: none;
    }
  }
  &__cta {
    font-weight: 600;
  }
  &__close {
    position: absolute;
    top:50%;
    right:2rem;
    transform:translateY(-50%) scale(1.1);
    opacity:1;
    padding: 0;
    appearance: none;
    background: none;
    border: none;
    color: var(--fill-white);
    transition: 0.2s ease-in-out all;
    cursor: pointer;

    .hidden & {
      transform:translateY(-51%) scale(1.1);
      opacity:0;
    }
  }
}

  

    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    :is(.section__rail) {
  .feature-banner {
    margin: 0 auto;
    width: auto;
    padding: 0;
    width: 100%;
    &__media{
      display: none;
    }
    &__content {
      padding: 2rem;
      background-color: transparent;
    }
    &__cta {
      flex-direction: column;
      align-items: center;
      .btn {
        max-width: fit-content;
        min-width: none;
      }
    }
    &__title {
      font-size: $h3Size;
    }
  }
}
.feature-banner {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 6.375rem 2rem;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  max-width: none;
  width: 100vw;
  z-index: 1;
  &__media {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
  }
  &__image,
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // block covers picture element padding issues
    display: block;
    margin: auto;
    pointer-events: none;
  }
  &__content {
    background-color: var(--fill-white);
    border-radius: $cornerRound;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 10;
    width: 95%;
    max-width: 53.75rem;
    padding: 1.875rem;
    text-align: center;
  }
  &__title {
    color: var(--fill-blue);
  }
  &__copy {
    font-weight: 400;
  }
  &__cta {
    font-size: 1rem;
    display: flex;
    margin: 0.5rem 0 0;
    gap: 1rem;
    justify-content: center;
    @include mqdn(md) {
      flex-wrap: wrap;
      .btn {
        flex: 1 1 100%;
      }
    }
  }
}
  